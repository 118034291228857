import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PureCanvas from './pure-canvas'

export default class Canvas extends Component {
  static propTypes = {
    el: PropTypes.object.isRequired,
    size: PropTypes.array.isRequired,
    fw: PropTypes.number.isRequired,
    pos: PropTypes.array.isRequired,
    step: PropTypes.number.isRequired
  }

  componentDidUpdate(prevProps) {
    const { el, fw, pos, size, step } = this.props
    if (el !== prevProps.el || this.props.fw !== prevProps.fw || this.props.step !== prevProps.step) {
      //Чистим сцену
      this.ctx.save()
      this.ctx.clearRect(pos[0], pos[1], size[0], size[1])
      this.ctx.restore()
      // console.log(el.naturalHeight)

      //Рисуем
      this.ctx.drawImage(
        el, //Объект Image анимации
        Math.round(fw * step), //Берем текущий кадр, ширина кадра * текущий шаг
        0, //Кадры идут один за другим, тут 0
        size[0], //Вырез в ширину объекта
        size[1], //И в высоту
        pos[0], //Размещаем по горизонтали на холсте
        pos[1], //И по вертикали
        size[0], //Ширина как у кадра
        size[1] //Ну и высота
      )
    }
  }

  render() {
    return <PureCanvas size={this.props.size} contextRef={(ctx) => (this.ctx = ctx)} />
  }
}
