import React, { Component } from 'react'
import CanvasAnimation from '../common/canvas-animation'

export default class Agent extends Component {
  render() {
    const { images } = this.props
    return (
      <div className="agent">
        <div className="guys">
          <CanvasAnimation delay={300} once={true} src={images[2]} size={[324, 340]} fw={324} steps={1} speed={3} />
        </div>
        <div className="shadow">
          <img src={images[3]} alt="" />
        </div>
        <img src={images[0]} alt="" />
        <div className="agent__hair">
          <CanvasAnimation src={images[1]} size={[419, 428]} fw={419} steps={4} speed={2} />
        </div>
        <div className="agent__shirt">
          <CanvasAnimation src={images[4]} size={[310, 500]} fw={310} steps={17} speed={1} />
        </div>
        <div className="agent__tie">
          <CanvasAnimation src={images[5]} size={[105, 311]} fw={105} steps={0} speed={1} />
        </div>
        <div className="agent__smoke agent__smoke--1">
          <img src={images[7]} alt="" />
        </div>
        <div className="agent__smoke agent__smoke--2">
          <CanvasAnimation src={images[8]} size={[585, 481]} fw={585} steps={12} speed={2} />
        </div>
        <div className="agent__pipe">
          <CanvasAnimation src={images[6]} size={[230, 112]} fw={230} steps={6} speed={2} />
        </div>
      </div>
    )
  }
}
