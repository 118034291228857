import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'

import { loadingSelector, loadingSuccess, getPage, imagesSelector } from '../../redux/modules/level1'
import { openModal, closeModal, messageSelector, errorSelector, successSelector, openSelector, getNextSelector, sendForm, closeSuccess, closeError, openSocialModal } from '../../redux/modules/modal'
import Icons from './icons'
import CanvasAnimation from '../common/canvas-animation'

import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'
import { setLevel } from '../../redux/modules/access'

class Level1 extends Component {
  state = {
    x: 0,
    step: 1,
    start: 0
  }

  id = null

  render() {
    const { loading, images, loadingSuccess, open, error, message, success, closeModal, sendForm, closeSuccess, closeError } = this.props
    const { step } = this.state

    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />

    return (
      <Fragment>
        <Header style={3} />
        {step === 3 ? this.step3 : this.steps}
        {open && <Modal hidebtn={true} getNext={this.nextStep} send={sendForm} close={closeModal} success={success} error={error} category="1" />}
        {error && <ErrorMessage close={closeError} message={message} />}
        {success && <SuccessMessage nextStep={this.nextStep} close={closeSuccess} />}
      </Fragment>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step !== prevState.step && this.state.step === 3) {
      this.props.setLevel(2)
      this.id = setTimeout(() => {
        this.props.getPage('/level-2')
      }, 2000)
    }

    if ((this.props.userIsAuthorized && prevProps.userIsAuthorized !== this.props.userIsAuthorized && this.state.start) || (this.props.userIsAuthorized && prevState.start !== this.state.start && this.state.start)) {
      this.props.openModal()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  get steps() {
    const { step } = this.state
    return (
      <div className={step === 1 ? `level1 page fade-in` : `level1 page fade-in level1_step2`} onMouseMove={this.handleMouseMove}>
        <div className="content">
          <div className="level1__bg" style={{ transform: `translateX(${this.state.x / -2}%)` }}></div>
          <div className="level1__top">
            <span style={{ transform: `translateX(${this.state.x / 2}%)` }}></span>
          </div>
          {step === 1 && (
            <div className="level1__block level1__block1">
              <img src="/images/level1/block1.png" alt="" />
            </div>
          )}
          {step === 1 && (
            <div className="level1__block level1__block2">
              <img src="/images/level1/block2.png" alt="" />
            </div>
          )}

          <div className="spacer">
            <img src="/images/spacer.png" alt="" />
            <div className="level1__block level1__block3">
              <img src="/images/level1/block3.png" alt="" />
            </div>
            {step === 1 && (
              <div className="level1__path">
                <img src="/images/level1/path.png" alt="" />
                <img src="/images/level1/path.svg" className="img1" alt="" />
                <img src="/images/level1/path2.svg" className="img2" alt="" />
                <img src="/images/level1/path3.svg" className="img3" alt="" />
                <img src="/images/level1/path4.svg" className="img4" alt="" />
                <img src="/images/level1/path5.svg" className="img5" alt="" />
                <img src="/images/level1/path6.svg" className="img6" alt="" />
              </div>
            )}
            <div className="level1__emitter">
              <img src={`/images/level1/emitter${step}.png`} alt="" />
            </div>
            {step === 1 && <Icons />}

            <div className="level1__agent">
              <img src="/images/level1/agent.png" alt="" />
            </div>
            {step === 2 && (
              <div className="level1__step2-agent">
                <img src="/images/level1/level1_step2_agent.png" alt="" />
              </div>
            )}

            {step === 2 && (
              <div className="level1__line1">
                <CanvasAnimation src="/images/level1/line.png" size={[683, 125]} fw={683} steps={11} speed={1} />
              </div>
            )}

            {step === 2 && (
              <div className="level1__line2">
                <CanvasAnimation src="/images/level1/line2.png" size={[683, 125]} fw={683} steps={11} speed={1} />
              </div>
            )}

            {step === 2 && (
              <>
                <div className="level1__bubble">
                  <img className="level1__bubble__img" src="/images/level1/bubble.png" alt="" />
                  <img className="level1__bubble__text" src="/images/level1/bubble_text.png" alt="" />
                  <div className="btn-wrap">
                    <div className="btn button2" onClick={this.nextStep}>
                      <span>осмотреть объект</span>
                      <span>внутри</span>
                    </div>
                  </div>
                </div>

                <div className="btn-wrap mobile">
                  <div className="btn button2" onClick={this.nextStep}>
                    <span>осмотреть объект</span>
                    <span>внутри</span>
                  </div>
                </div>
              </>
            )}
            {step === 1 && (
              <div className="level1__bubble2">
                <img src="/images/level1/modal.png" alt="" />
                <img src="/images/level1/modal-text.png" alt="" />
                <div className="btn-wrap">
                  <div className="btn button2" onClick={this.getHelp}>
                    <span>Помочь сдвинуть</span>
                    <span>блок</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  get step3() {
    return (
      <div className="page fade-in level1-step3">
        <div className="level1__step3-bg"></div>
        <div className="level1__step3-bg2"></div>
        <div className="content">
          <div className="spacer" style={{ left: 0, transform: 'none' }}>
            <img src="/images/spacer.png" alt="" />
            <div className="level1__emitter3">
              <img src={`/images/level1/emitter3.png`} alt="" />
            </div>
            <div className="level1__step3-block">
              <img src={`/images/level1/step3-block.png`} alt="" />
            </div>
            <div className="level1__step3-path">
              <img src="/images/level1/step3-path.png" alt="" />
              <img src="/images/level1/step3-path.svg" className="img2" alt="" />
              <img src="/images/level1/step3-path2.svg" className="img3" alt="" />
              <img src="/images/level1/step3-path3.svg" className="img4" alt="" />
              <img src="/images/level1/step3-path4.svg" className="img5" alt="" />
              <img src="/images/level1/step3-path5.svg" className="img6" alt="" />
            </div>
            <div className="level1__step3-agent">
              <img src={`/images/level1/step3_agent.png`} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getHelp = () => {
    if (!this.props.userIsAuthorized) return this.nextStepNotAuth()
    this.props.openModal()
  }

  nextStepNotAuth = () => {
    this.props.openSocialModal()
    this.setState({ start: this.state.start + 1 })
  }

  nextStep = () => this.setState({ step: this.state.step + 1 })

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),
    message: messageSelector(state),
    success: successSelector(state),
    error: errorSelector(state),
    open: openSelector(state),
    getNext: getNextSelector(state),
    userIsAuthorized: state.access.userIsAuthorized
  }),
  { loadingSuccess, getPage, openModal, closeModal, sendForm, closeSuccess, closeError, setLevel, openSocialModal }
)(Level1)
