import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'

import { loadingSelector, imagesSelector, loadingSuccess, getPage } from '../../redux/modules/level3'
import { openModal, closeModal, messageSelector, errorSelector, successSelector, openSelector, sendForm, closeSuccess, closeError } from '../../redux/modules/modal'
import Icons from './icons'

import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'
import { setLevel } from '../../redux/modules/access'
import Counter from '../common/counter'

class Level1 extends Component {
  state = {
    x: 0,
    step: 1
  }

  id = null

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />
    const { step } = this.state
    return (
      <Fragment>
        <Header style={3} />
        {this.steps}
        {this.props.open && <Modal getNext={this.nextStep} send={this.props.sendForm} close={this.props.closeModal} success={this.props.success} error={this.props.error} category="5" />}
        {this.props.error && <ErrorMessage close={this.props.closeError} message={this.props.message} />}
        {this.props.success && <SuccessMessage nextStep={this.nextStep} close={this.props.closeSuccess} />}
      </Fragment>
    )
  }

  componentDidMount() {
    if (this.props.loading) return false
    this.id = setTimeout(() => {
      this.setState({ step: 2 })
    }, 4000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 1 && !this.props.loading && this.props.loading !== prevProps.loading) {
      this.id = setTimeout(() => {
        this.setState({ step: 2 })
      }, 4000)
    }
    // if (this.state.step === 5 && this.state.step !== prevState.step) {
    //   this.id = setTimeout(() => {
    //     this.props.setLevel(4)
    //     this.props.getPage('/level-4')
    //   }, 5000)
    // }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  get steps() {
    const { step } = this.state
    return (
      <div className={`level3 page fade-in step-${step}`} onMouseMove={this.handleMouseMove}>
        <div className="content">
          {step === 1 && (
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />
              <div className="level3__way">
                <img src="/images/level3/way.png" alt="" />
              </div>
            </div>
          )}

          {step < 5 && <div className="level3__bg" style={{ transform: `translateX(${this.state.x / -2}%)` }}></div>}

          <div className="level3__top">
            <div style={{ transform: `translateX(${this.state.x / 2}%)` }}>
              <span></span>
              {step >= 4 && <span className="level3__top__bg"></span>}
              {step === 1 && (
                <Fragment>
                  <div className="level3__tree level3__tree-1">
                    <img src="/images/level3/tree.png" alt="" />
                  </div>
                  <div className="level3__tree level3__tree-2">
                    <img src="/images/level3/tree2.png" alt="" />
                  </div>
                  <div className="level3__tree level3__tree-3">
                    <img src="/images/level3/tree3.png" alt="" />
                  </div>
                  <div className="level3__tree level3__tree-4">
                    <img src="/images/level3/tree4.png" alt="" />
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="spacer">
            <img src="/images/spacer.png" alt="" />
            {step < 4 && (
              <div className="level3__capsule">
                <img src={`/images/level3/capsule.png`} alt="" />
              </div>
            )}
            {step < 4 && (
              <div className="level3__bubble">
                <img className="level3__bubble__img" src="/images/level3/bubble.png" alt="" />
                <img className="level3__bubble__text" src="/images/level3/text.png" alt="" />
              </div>
            )}
            {step < 4 && (
              <div className="level3__agent">
                <img src="/images/level3/agent.png" alt="" />
              </div>
            )}
            {step > 1 && step < 4 && (
              <div className="level3__agent2">
                <img src="/images/level3/agent2.png" alt="" />
              </div>
            )}
            {step === 3 && (
              <div className="level3__agent3">
                <img src="/images/level3/agent3.png" alt="" />
              </div>
            )}
            {step < 4 && (
              <Fragment>
                <div className="level3__base">
                  <img src="/images/level3/base.png" alt="" />
                </div>
                <div className="level3__base2">
                  <img src="/images/level3/base2.png" alt="" />
                </div>
                <div className="level3__fire level3__fire-1">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>
                <div className="level3__fire level3__fire-2">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>
                <div className="level3__fire level3__fire-3">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>

                <div className="level3__fire level3__fire-1 level3__fire-1--right">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>
                <div className="level3__fire level3__fire-2 level3__fire-2--right">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>
                <div className="level3__fire level3__fire-3 level3__fire-3--right">
                  <img className="level3__fire__shadow" src="/images/level3/fire.png" alt="" />
                  <img className="level3__fire__icon" src="/images/level3/fire-icon.svg" alt="" />
                </div>
              </Fragment>
            )}

            {step === 2 && (
              <div className="level3__bubble2">
                <img src="/images/level3/bubble2.png" alt="" />
                <img className="level3__bubble__text" src="/images/level3/text2.png" alt="" />
              </div>
            )}
            {step === 2 && (
              <div className="level3__hand">
                <img src="/images/level3/hand.png" alt="" />
              </div>
            )}
            {step === 2 && (
              <div className="btn-wrap" onClick={this.nextStep}>
                <div className="btn button4">
                  <span>скорее внутрь!</span>
                </div>
              </div>
            )}
            {step === 2 && <Icons />}
            {step === 3 && (
              <div className="level3__bubble3">
                <img className="level3__bubble__img" src="/images/level3/bubble3.png" alt="" />
                <img className="level3__bubble__text" src="/images/level3/text3.png" alt="" />
              </div>
            )}
            {step === 3 && (
              <div className="flea-wrap">
                <div className="level3__flea level3__flea1">
                  <img src="/images/level3/flea.png" alt="" />
                  <div className="level3__light">
                    <img src="/images/level3/light2.png" alt="" />
                    <img className="light2" src="/images/level3/light3.png" alt="" />
                    <img className="light3" src="/images/level3/light1.png" alt="" />
                  </div>
                </div>
                <div className="level3__flea level3__flea2">
                  <img src="/images/level3/flea.png" alt="" />
                  <div className="level3__light">
                    <img src="/images/level3/light3.png" alt="" />
                    <img className="light2" src="/images/level3/light1.png" alt="" />
                    <img className="light3" src="/images/level3/light2.png" alt="" />
                  </div>
                </div>
                <div className="level3__flea level3__flea3">
                  <img src="/images/level3/flea.png" alt="" />
                  <div className="level3__light">
                    <img src="/images/level3/light1.png" alt="" />
                    <img className="light2" src="/images/level3/light2.png" alt="" />
                    <img className="light3" src="/images/level3/light3.png" alt="" />
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="btn-wrap2">
                <div className="btn button2" onClick={this.props.openModal}>
                  <span>Позвать самого</span>
                  <span>находчивого</span>
                </div>
              </div>
            )}
            {step === 4 && (
              <div className="level3__bubble4">
                <img src="/images/level3/bubble4.png" alt="" />
              </div>
            )}
            {step === 4 && (
              <div className="level3__bubble5">
                <img src="/images/level3/bubble5.png" alt="" />
              </div>
            )}

            {step === 4 && (
              <div className="level3__flea4">
                <img src="/images/level3/flea2.png" alt="" />
                <img className="level3__flea2-2" src="/images/level3/flea2-2.png" alt="" />
              </div>
            )}
            {step === 4 && (
              <div className="level3__hand2">
                <img src="/images/level3/hand2.png" alt="" />
              </div>
            )}

            {step === 4 && <Counter fire={true} callback={this.nextStep} />}

            {step === 5 && (
              <Fragment>
                <div className="level3-bg-mobile mobile">
                  <img src="/images/mobile/layer3_bg5.png" />
                </div>

                <div className="level3__hand3">
                  <img src="/images/level3/hand3.png" alt="" />
                </div>
              </Fragment>
            )}
            {step === 5 && (
              <div className="level3__bubble6">
                <img src="/images/level3/bubble6.png" alt="" />
              </div>
            )}
            {step === 5 && (
              <div className="level3__flea5">
                <img src="/images/level3/flea3-shadow.png" alt="" />
                <img className="level3__flea3__agent" src="/images/level3/flea3.png" alt="" />
              </div>
            )}
            {step === 5 && (
              <div className="level3__agent4-wrap">
                <div className="spacer">
                  <img src="/images/spacer.png" alt="" />
                  <div className="level3__agent4">
                    <img src="/images/level3/agent4.png" alt="" />
                  </div>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className="level3__bubble7">
                <img src="/images/level3/bubble7.png" alt="" />
              </div>
            )}
          </div>
        </div>
        {step === 5 && (
          <div className="btn-wrap lv3-btn-wrap">
            <div className="btn button3" onClick={this.nextLevel}>
              <span>дальше</span>
            </div>
          </div>
        )}
      </div>
    )
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }

  nextLevel = () => {
    this.props.setLevel(4)
    this.props.getPage('/level-4')
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),
    message: messageSelector(state),
    success: successSelector(state),
    error: errorSelector(state),
    open: openSelector(state)
  }),
  { loadingSuccess, getPage, openModal, closeModal, sendForm, closeSuccess, closeError, setLevel }
)(Level1)
