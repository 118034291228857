import React, { Component, Fragment } from 'react'
import { getRandom } from '../../services/utils'

class Icons extends Component {
  state = {
    icons: [],
    index: 1
  }

  id = null

  componentDidMount() {
    this.id = setInterval(() => {
      this.create()
    }, 400)
  }

  componentWillUnmount() {
    clearInterval(this.id)
  }

  render() {
    return <div className="level1__icons">{this.state.icons}</div>
  }

  create = () => {
    this.setState({
      icons: [
        ...this.state.icons,
        <div key={this.state.index} className={`l-icon2 l-anim-${getRandom(6, 1)} l-color-${getRandom(2, 1)}`}>
          <div></div>
        </div>
      ],
      index: this.state.index + 1
    })
  }
}

export default Icons
