import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from './redux/history'
import { YMInitializer } from 'react-yandex-metrika'
import './css/app.css'
import './css/app_as.css'
import './css/adaptive.css'
import './css/mobile.css'

import PrivateRoute from './components/common/private-router'
import Footer from './components/layout/footer'
import Home from './components/home'
import First from './components/first'
import Intro from './components/intro'
import Level1 from './components/level1'
import Level2 from './components/level2'
import Level3 from './components/level3'
import Level4 from './components/level4'
import Level5 from './components/level5'
import Finish from './components/finish'
import ModalSocial from './components/modal/modal-auth-social'
import ModalId from './components/modal/modal-auth-id'
import ModalAlmostComplete from './components/modal/modal-almost-complete'
import ModalSignUp from './components/modal/modal-sign-up'
import ModalWhereDoYouWork from './components/modal/modal-where-do-you-work'
import ModalSocialSuccess from './components/modal/social-success'
import ModalSingupSuccess from './components/modal/sign-up-success'
import ModalForgotPassword from './components/modal/forgot-password'
import ErrorMessage from './components/modal/error'
import Ratings from './components/ratings'
import Help from './components/help'
import Game from './components/game'

import { setMobile } from './redux/modules/mobile'
import { closeErrorMessage } from './redux/modules/modal'
import { userIsAuthorized } from './redux/modules/access'

class App extends Component {
  componentDidMount() {
    this.props.setMobile(window.innerWidth <= 990)
    window.addEventListener('resize', this._onResize)
    this.props.userIsAuthorized()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
  }

  render() {
    if (!this.props.checkedAuthorized) return <div className="loader">Loading...</div>
    return (
      <ConnectedRouter history={history}>
        <div>
          <YMInitializer accounts={[51203048]} />

          {this.props.open_social && <ModalSocial />}
          {this.props.open_id && <ModalId />}
          {this.props.open_almost_complete && <ModalAlmostComplete />}
          {this.props.open_where_do_you_work && <ModalWhereDoYouWork />}
          {this.props.open_sign_up && <ModalSignUp />}
          {this.props.open_social_success && <ModalSocialSuccess />}
          {this.props.sign_up_success && <ModalSingupSuccess />}
          {this.props.open_forgot_password && <ModalForgotPassword />}

          {this.props.error_open && <ErrorMessage message={this.props.error_message} close={this.props.closeErrorMessage} />}

          <div className="modal-page-wrapper-mobile">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/first" exact component={this.first} />
              <Route path="/intro" exact component={this.intro} />
              <Route path="/level-1" exact component={this.level1} />
              <PrivateRoute path="/level-2" exact component={this.level2} />
              <PrivateRoute path="/level-3" exact component={this.level3} />
              <PrivateRoute path="/level-4" exact component={this.level4} />
              <PrivateRoute path="/level-5" exact component={this.level5} />
              <PrivateRoute path="/finish" exact component={this.finish} />
              <PrivateRoute path="/finish" exact component={this.finish} />
              <Route path="/ratings" exact>
                <Ratings />
              </Route>
              <Route path="/help/:id" exact>
                <Help />
              </Route>
              <Route path="/game" exact>
                <Game />
              </Route>
            </Switch>
            <Footer />
          </div>
        </div>
      </ConnectedRouter>
    )
  }

  first = ({ match }) => {
    if (this.props.level < -1) return <Redirect to="/" />
    if (match.isExact) return <First />
  }

  intro = ({ match }) => {
    if (this.props.level < 0) return <Redirect to="/first" />
    if (match.isExact) return <Intro />
  }

  level1 = ({ match }) => {
    if (this.props.level < 1) return <Redirect to="/intro" />
    if (match.isExact) return <Level1 />
  }

  level2 = ({ match }) => {
    if (this.props.level < 2) return <Redirect to="/level-1" />
    if (match.isExact) return <Level2 />
  }

  level3 = ({ match }) => {
    if (this.props.level < 3) return <Redirect to="/level-2" />
    if (match.isExact) return <Level3 />
  }

  level4 = ({ match }) => {
    if (this.props.level < 4) return <Redirect to="/level-3" />
    if (match.isExact) return <Level4 />
  }

  level5 = ({ match }) => {
    if (this.props.level < 5) return <Redirect to="/level-4" />
    if (match.isExact) return <Level5 />
  }

  finish = ({ match }) => {
    if (this.props.level < 6) return <Redirect to="/level-5" />
    if (match.isExact) return <Finish />
  }

  _onResize = () => {
    const mobile = window.innerWidth <= 990

    if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
      this.props.setMobile(mobile)
    }
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    level: state.access.level,
    checkedAuthorized: state.access.checkedAuthorized,

    open_social: state.modal.social.open,
    open_almost_complete: state.modal.almost_complete.open,
    open_id: state.modal.id.open,
    open_sign_up: state.modal.sign_up.open,
    sign_up_success: state.modal.sign_up.success,
    open_where_do_you_work: state.modal.where_do_you_work.open,

    error_open: state.modal.error_message.open,
    error_message: state.modal.error_message.message,

    open_social_success: state.modal.social_success.open,
    open_forgot_password: state.modal.forgot_password.open
  }),
  { setMobile, userIsAuthorized, closeErrorMessage }
)(App)
