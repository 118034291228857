import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Menu from './menu'
import { hit } from '../common/metrics'

class Footer extends Component {
  static propTypes = {
    style: PropTypes.number.isRequired
  }

  componentDidMount() {
    hit(window.location.pathname)
  }

  state = {
    open: false
  }

  render() {
    return (
      <Fragment>
        <div className={`header style-${this.props.style}`}>
          <div className="content">
            <Link to="/" className="logo"></Link>
            <div className="nav-icon" onClick={this.toggle}></div>
          </div>
        </div>
        {this.state.open && <Menu open={this.state.open} toggle={this.toggle} />}
      </Fragment>
    )
  }

  toggle = () => this.setState({ open: !this.state.open })
}

export default Footer
