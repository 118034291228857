import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Canvas from './canvas'

export default class CanvasAnimation extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired, // Путь к изображению
    size: PropTypes.array.isRequired, // размер объекта
    fw: PropTypes.number.isRequired, // Ширина кадра анимации
    steps: PropTypes.number.isRequired, // Количество кадров анимации, считам от 0
    speed: PropTypes.number.isRequired, // Скорость анимации
    play: PropTypes.bool,
    pause: PropTypes.bool,
    once: PropTypes.bool // Пройти по кадрам анимации 1 раз, игнорирует свойство pause
  }

  state = {
    pos: [0, 0], // позиция объекта
    el: {}, // Объект Image
    step: 0, // Текущий шаг анимации
    curr: 0 // Счетчик кадров
  }

  id = null

  componentDidMount() {
    var img = new Image()
    img.src = this.props.src
    img.onload = () => {
      this.setState({
        el: img,
        step: this.props.pause && !this.props.once ? this.props.steps : 0
      })

      // console.log(img.naturalWidth, img.naturalHeight, this.props.size, this.props.fw)

      if (this.props.delay) {
        setTimeout(() => {
          this.play()
        }, this.props.delay)
        return
      }
      this.play()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.pause && this.props.pause !== prevProps.pause) this.pause()
    if (this.props.play && this.props.play !== prevProps.play) this.play()
  }

  updateAnimationState = () => {
    if ((this.props.pause && !this.props.once) || (this.props.once && this.state.step >= this.props.steps)) {
      this.pause()
      return false
    }
    if (this.state.curr < this.props.speed) return this.setState({ curr: this.state.curr + 1 })

    this.setState({
      step: this.state.step >= this.props.steps ? 0 : this.state.step + 1,
      curr: 0
    })
  }

  componentWillUnmount() {
    this.pause()
  }

  play = () => {
    this.id = setInterval(() => this.updateAnimationState(), 800 / 16)
  }

  pause = () => {
    clearInterval(this.id)
  }

  render() {
    // return null
    return <Canvas {...this.state} {...this.props} />
  }
}
