import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Agent from './agent'
import Loader from '../common/loader'
import Header from '../layout/header'

import { loadingSelector, imagesSelector, loadingSuccess, getPage } from '../../redux/modules/home'
import { setLevel, userIdSelector } from '../../redux/modules/access'
import { openSocialModal } from '../../redux/modules/modal'

class Home extends Component {
  state = {
    moveLeft: true,
    start: false
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.userIsAuthorized && prevProps.userIsAuthorized !== this.props.userIsAuthorized && this.state.start) || (this.props.userIsAuthorized && prevState.start !== this.state.start && this.state.start)) {
      this.props.setLevel(0)
      this.props.getPage('/first')
    }
  }

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />

    return (
      <Fragment>
        <Header style={1} />
        <div className={this.state.moveLeft ? 'home page fade-in hover-left' : 'home page fade-in hover-right'} onMouseMove={this.handleMouseMove}>
          <div className="content">
            <Agent images={images} />
            <div>
              <div className="home__title">Агент х5</div>
              <div className="home__descr">операция "спасение"</div>
              <div className="home__descr2">Помоги агенту спасти коллегу.</div>

              <div className="btn-wrap">
                <Link className="btn btn1" to="/first" onClick={this.handleSetLevel}>
                  <span>начать операцию</span>
                </Link>
              </div>
            </div>
            <div className="info">
              <span>помоги агенту спасти</span>
              <br />
              <span>коллегу</span>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  handleSetLevel = (ev) => {
    return this.props.setLevel(-1)
    // if (this.props.userIsAuthorized) return this.props.setLevel(-1)
    ev.preventDefault()
    this.props.openSocialModal()
    this.setState({ start: this.state.start + 1 })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),
    userIsAuthorized: state.access.userIsAuthorized
  }),
  { loadingSuccess, setLevel, openSocialModal, getPage }
)(Home)
