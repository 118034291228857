// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'home'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: ['/images/agent.png', '/images/hair.png', '/images/guys.png', '/images/shadow.png', '/images/shirt.png', '/images/tie.png', '/images/pipe.png', '/images/smoke1.png', '/images/smoke2.png'],
  loading: true
}

export default (state = defaultState, action) => {
  const { type } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
