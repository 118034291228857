import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'
import CanvasAnimation from '../common/canvas-animation'

import { loadingSelector, imagesSelector, loadingSuccess } from '../../redux/modules/first'
import { setLevel } from '../../redux/modules/access'

class Intro extends Component {
  state = {
    x: 0
  }

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />

    return (
      <Fragment>
        <Header style={2} />
        <div className="first page fade-in" onMouseMove={this.handleMouseMove}>
          <div className="content">
            <div className="intro__bg" style={{ transform: `translateX(${this.state.x}%)` }}></div>
            <div className="first__text">
              <p>
                Теперь вы помощник <br /> секретного агента Х5.
              </p>
              <p>
                У него задание: <br /> связаться с агентом Х8.
                <br /> и получить
                <br /> особо важную информацию. <br />
                Помогите ему <br />
                выполнить миссию!
              </p>
              <Link to="/intro" className="btn button2" onClick={this.handleSetLevel}>
                <span>начать</span>
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  handleSetLevel = () => this.props.setLevel(0)

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state)
  }),
  { loadingSuccess, setLevel }
)(Intro)
