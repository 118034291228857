import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Counter extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired
  }

  state = {
    counter: 60
  }

  id = null

  componentDidUpdate() {
    if (this.state.counter === 0) this.props.callback()
  }

  componentDidMount() {
    this.id = setInterval(() => this.setState({ counter: this.state.counter - 1 }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.id)
  }

  render() {
    return <span>{this.state.counter}</span>
  }
}

export default Counter
