// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level3'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/level3/top.png',
    '/images/level1/bg.png',
    '/images/level3/way.png',
    '/images/level3/way-pattern.png',
    '/images/level3/tree.png',
    '/images/level3/tree2.png',
    '/images/level3/tree3.png',
    '/images/level3/tree4.png',
    '/images/level3/capsule.png',
    '/images/level3/bubble.png',
    '/images/level3/text.png',
    '/images/level3/agent.png',
    '/images/level3/agent2.png',
    '/images/level3/base.png',
    '/images/level3/fire.png',
    '/images/level3/hand.png',
    '/images/level3/agent3.png',
    '/images/level3/bubble2.png',
    '/images/level3/text2.png',
    '/images/level3/i1.svg',
    '/images/level3/i2.svg',
    '/images/level3/i3.svg',
    '/images/level3/i4.svg',
    '/images/level3/i5.svg',
    '/images/level3/i6.svg',
    '/images/level3/i7.svg',
    '/images/level3/i8.svg',
    '/images/level3/i9.svg',
    '/images/level3/i10.svg',
    '/images/level3/fire-icon.svg',
    '/images/level3/bubble3.png',
    '/images/level3/text3.png',
    '/images/level3/flea.png',
    '/images/level3/light1.png',
    '/images/level3/light2.png',
    '/images/level3/light3.png',
    '/images/level3/bubble4.png',
    '/images/level3/bubble5.png',
    '/images/level3/flea2.png',
    '/images/level3/hand2.png',
    '/images/level3/agent4.png',
    '/images/level3/hand3.png',
    '/images/level3/bubble6.png',
    '/images/level3/flea3.png',
    '/images/level3/flea3-shadow.png',
    '/images/level3/agent4.png',
    '/images/level3/bubble7.png'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
export const openSelector = (state) => state[moduleName].modal.open
export const successSelector = (state) => state[moduleName].modal.success
export const errorSelector = (state) => state[moduleName].modal.error
export const messageSelector = (state) => state[moduleName].modal.message
export const getNextSelector = (state) => state[moduleName].modal.getNext
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
