import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Message extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
  }

  state = {
    visible: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.close, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    const { visible } = this.state
    return (
      <div className={`modal modal-message modal-error ${visible ? 'visible' : ''}`}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="modal-close ico-close" onClick={this.hide}></div>
              <div className="modal-content">
                <div className="modal-title6">Ошибка</div>
                <div className="modal-info2" dangerouslySetInnerHTML={{ __html: this.props.message }} />
                <div className="btn button5" onClick={this.hide}>
                  <span>закрыть</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }
}

export default Message
