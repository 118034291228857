import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'
import Icons from './icons'

import { loadingSelector, imagesSelector, loadingSuccess, getPage } from '../../redux/modules/level4'

import { openModal, closeModal, messageSelector, errorSelector, successSelector, openSelector, sendForm, closeSuccess, closeError } from '../../redux/modules/modal'
import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'

import { setLevel } from '../../redux/modules/access'

class Level4 extends Component {
  state = {
    step: 1
  }

  id = null

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />
    const { step } = this.state
    return (
      <Fragment>
        <Header style={step === 1 ? 3 : step === 3 ? 1 : 2} />
        <div className={`level4 page fade-in step-${step}`}>
          {step < 4 && <div className="level4__bg"></div>}
          {step >= 3 && <div className="level4__bg2"></div>}
          <div className="content">
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />
              {step === 1 && (
                <div className="eyes">
                  <div className="level4__eyes"></div>
                  <span className="l4-icon l4-icon-1"></span>
                  <span className="l4-icon l4-icon-2"></span>
                  <span className="l4-icon l4-icon-3"></span>
                  <span className="l4-icon l4-icon-4"></span>
                  <span className="l4-icon l4-icon-5"></span>
                  <span className="l4-icon l4-icon-6"></span>
                  <span className="l4-icon l4-icon-7"></span>
                  <span className="l4-icon l4-icon-8"></span>
                  <span className="l4-icon l4-icon-9"></span>
                  <span className="l4-icon l4-icon-10"></span>
                </div>
              )}
              {step === 2 && (
                <div className="level4__blast">
                  <img src="/images/level4/blast.png" alt="" />
                </div>
              )}
              {step === 3 && (
                <div className="level4__agent">
                  <img src="/images/level4/agent.png" alt="" />
                </div>
              )}
              {step === 3 && (
                <div className="level4__circle">
                  <img src="/images/level4/circle.png" alt="" />
                </div>
              )}
              {step === 3 && (
                <div className="level4__light1">
                  <img src="/images/level4/light1.png" alt="" />
                </div>
              )}
              {step === 3 && (
                <div className="level4__light2">
                  <img src="/images/level4/light2.png" alt="" />
                </div>
              )}
              {(step === 3 || step === 4) && (
                <div className="level4__bubble">
                  <img src="/images/level4/bubble.png" alt="" />
                </div>
              )}
              {(step === 3 || step === 4) && (
                <div className="level4__bubble2">
                  <img src="/images/level4/bubble2.png" alt="" />
                </div>
              )}
              {step === 3 && (
                <div className="btn-wrap" onClick={this.nextStep}>
                  <div className="btn button2">
                    <span>что дальше</span>
                  </div>
                </div>
              )}
              {step === 4 && (
                <div className="level4__agent2">
                  <img src="/images/level4/agent2.png" alt="" />
                </div>
              )}
              {(step === 4 || step === 5) && (
                <div className="level4__icons1">
                  <Icons />
                </div>
              )}
              {(step === 4 || step === 5) && (
                <div className="level4__icons2">
                  <Icons />
                </div>
              )}
              {step === 4 && (
                <div className="level4__blood level4__blood1">
                  <img src="/images/level4/blood.png" alt="" />
                </div>
              )}
              {(step === 4 || step === 5) && (
                <div className="level4__blood level4__blood2">
                  <img src="/images/level4/blood.png" alt="" />
                </div>
              )}
              {(step === 4 || step === 5) && (
                <div className="level4__flea">
                  <img src="/images/level4/flea.png" alt="" />
                </div>
              )}
              {step === 4 && (
                <div className="level4__bubble3">
                  <img src="/images/level4/bubble3.png" alt="" />
                  <div className="btn-wrap2">
                    <div className="btn button2 btn2--white" onClick={this.nextStep}>
                      <span>осмотреться</span>
                    </div>
                  </div>
                </div>
              )}
              {step === 4 && (
                <div className="level4__icons3">
                  <Icons />
                </div>
              )}
              {step === 4 && (
                <div className="level4__bolt level4__bolt1">
                  <img src="/images/level4/light3.png" alt="" />
                </div>
              )}
              {step === 4 && (
                <div className="level4__bolt level4__bolt2">
                  <img src="/images/level4/light3.png" alt="" />
                </div>
              )}
            </div>

            {step > 4 && (
              <div className="spacer spacer-2">
                <img src="/images/spacer.png" alt="" />

                {step === 5 && (
                  <Fragment>
                    <div className="level4__bubble4">
                      <img src="/images/level4/bubble4.png" alt="" />
                    </div>
                    <div className="level4__agent3">
                      <img src="/images/level4/agent3.png" alt="" />
                    </div>
                    <div className="level4__capsule1">
                      <div className="level4__capsule1__x8"></div>
                      <img src="/images/level4/capsule.png" alt="" />
                    </div>
                    <div className="level4__capsule2">
                      <div className="level4__capsule2__x8"></div>
                      <img src="/images/level4/capsule.png" alt="" />
                    </div>
                    <div className="level4__capsule3">
                      <div className="level4__capsule3__x8"></div>
                      <img src="/images/level4/capsule.png" alt="" />
                    </div>
                    <div className="level4__bubble5">
                      <img src="/images/level4/bubble5.png" alt="" />
                    </div>
                    <div className="btn-wrap3" onClick={this.props.openModal}>
                      <div className="btn button-scale">
                        <span>позвать</span>
                      </div>
                    </div>
                  </Fragment>
                )}

                {step === 6 && (
                  <Fragment>
                    <div className="level4__capsule4">
                      <div>
                        <div className="level4__capsule4__x8"></div>
                        <img src="/images/level4/capsule2.png" alt="" />
                      </div>
                    </div>
                    <div className="level4__agent4">
                      <img src="/images/level4/agent4.png" alt="" />
                    </div>
                    <div className="level4__line1">
                      <img src="/images/level4/line1.png" alt="" />
                    </div>
                    <div className="level4__line2">
                      <img src="/images/level4/line2.png" alt="" />
                    </div>
                    <div className="level4__line3">
                      <img src="/images/level4/line3.png" alt="" />
                    </div>
                    <div className="level4__bubble6">
                      <img src="/images/level4/bubble6.png" alt="" />
                    </div>
                    <div className="level4__bubble7">
                      <img src="/images/level4/bubble7.png" alt="" />
                    </div>
                    <div className="level4__bubble8">
                      <img src="/images/level4/bubble8.png" alt="" />
                    </div>

                    <div className="btn-wrap4" onClick={this.nextStep}>
                      <div className="btn button-scale button-scale--size2">
                        <span>Отключить капсулу</span>
                      </div>
                    </div>
                  </Fragment>
                )}

                {step === 7 && (
                  <Fragment>
                    <div className="level4__guys">
                      <div className="layer level4__s level4__s1">
                        <img src="/images/level4/s1.png" alt="" />
                      </div>
                      <div className="layer level4__s level4__s2">
                        <img src="/images/level4/s2.png" alt="" />
                      </div>
                      <div className="layer level4__s level4__s3">
                        <img src="/images/level4/s3.png" alt="" />
                      </div>
                      <div className="layer level4__s level4__s4">
                        <img src="/images/level4/s4.png" alt="" />
                      </div>
                      <img src="/images/level4/guys.png" alt="" />
                    </div>

                    <div className="level4__capsule1">
                      <div className="level4__capsule1__x8"></div>
                      <img src="/images/level4/capsule.png" alt="" />
                    </div>

                    <div className="level4__capsule2">
                      <div className="level4__capsule3__x8"></div>
                      <div className="level4__capsule__break"></div>
                      <img src="/images/level4/capsule.png" alt="" />
                    </div>
                    <div className="level4__bubble9">
                      <img src="/images/level4/bubble9.png" alt="" />
                    </div>
                  </Fragment>
                )}
              </div>
            )}
            {step === 7 && (
              <div className="spacer spacer-2 spacer-2_2">
                <img src="/images/spacer.png" alt="" />
                <div className="level4__signal">
                  <div>
                    <img src="/images/level4/signal.png" alt="" />
                    <img className="level4__signal__lines" src="/images/level4/signal-lines.png" alt="" />
                  </div>
                </div>

                <div className="btn-wrap4" onClick={this.nextLevel}>
                  <div className="btn button-scale">
                    <span>Скорее</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {this.props.open && <Modal getNext={this.nextStep} send={this.props.sendForm} close={this.props.closeModal} success={this.props.success} error={this.props.error} category="4" />}
        {this.props.error && <ErrorMessage close={this.props.closeError} message={this.props.message} />}
        {this.props.success && <SuccessMessage nextStep={this.nextStep} close={this.props.closeSuccess} />}
      </Fragment>
    )
  }

  componentDidMount(prevProps, prevState) {
    if (this.state.step === 1 && !this.props.loading) {
      this.id = setTimeout(() => {
        this.setState({ step: 2 })
      }, 3000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 1 && !this.props.loading && this.props.loading !== prevProps.loading) {
      this.id = setTimeout(() => {
        this.setState({ step: 2 })
      }, 3000)
    }
    if (this.state.step === 2 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 3 })
      }, 300)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }

  nextLevel = () => {
    this.props.setLevel(5)
    this.props.getPage('/level-5')
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),
    message: messageSelector(state),
    success: successSelector(state),
    error: errorSelector(state),
    open: openSelector(state)
  }),
  { loadingSuccess, closeModal, openModal, closeModal, sendForm, closeSuccess, closeError, setLevel, getPage }
)(Level4)
