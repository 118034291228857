import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Social from '../common/social'
import { openSignUp } from '../../redux/modules/modal'
import { logOut } from '../../redux/modules/access'

class Menu extends Component {
  render() {
    return (
      <div className="nav">
        <div className="content">
          <div>
            <div className="nav-inner">
              {this.props.userIsAuthorized ? this.profile : this.social}
              <div className="nav__col nav__right">
                <div className="nav__icons">
                  <span>
                    Рейтинг <br /> агентов&nbsp;х5{' '}
                  </span>
                </div>
                <Link to="/ratings"> Смотреть таблицу</Link>
              </div>
            </div>
            <div className="nav__close" onClick={this.props.toggle}></div>
          </div>
        </div>
      </div>
    )
  }

  get profile() {
    const { user } = this.props
    if (!this.props.user) return null
    return (
      <div className="nav__col nav__left nav__left--profile">
        <div>
          <span>
            {user.lastName} <br /> {user.name}
          </span>{' '}
          <br />
          <a href="#" onClick={this.handleLogout}>
            Выйти
          </a>
        </div>
        <div className="profile__photo">
          <span className="file-to-upload__photo">
            <span style={user.personalPhoto ? { backgroundColor: '#000', backgroundImage: `url(${user.personalPhoto})` } : {}}></span>
          </span>
        </div>
      </div>
    )
  }

  get social() {
    return (
      <Fragment>
        <div className="nav__col nav__left">
          <div className="nav__icons">
            <span>
              {' '}
              Авторизоваться <br /> через{' '}
            </span>
            <Social noicons={1} />
          </div>
          <div className="about-id">
            HappyID - это ваша сохраненная <br />
            регистрация из прошлых проектов Happy
          </div>
          <div className="btn btn10" onClick={this.handleSignUp}>
            <span>
              получить happy<i>id</i>
            </span>
          </div>
        </div>
        <div className="nav__col nav__middle">
          <div className="nav__icons">
            <span>
              {' '}
              Авторизоваться <br /> через соцсети{' '}
            </span>
            <Social noid={1} />
          </div>
        </div>
      </Fragment>
    )
  }

  handleSignUp = (e) => {
    e.preventDefault()
    this.props.openSignUp()
  }

  handleLogout = (e) => {
    e.preventDefault()
    // this.props.toggle()
    this.props.logOut()
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    userIsAuthorized: state.access.userIsAuthorized,
    user: state.access.user
  }),
  { openSignUp, logOut }
)(Menu)
