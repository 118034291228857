import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'
import { getPlaceData } from '../../redux/modules/access'
import { closeSignUpSuccess } from '../../redux/modules/modal'

class SocialSuccess extends Component {
  state = {
    visible: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const { success } = this.props
    if (success && prevProps.success !== success) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeSignUpSuccess, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return (
      <div className={`modal modal-message modal-id modal-id-success ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay" onClick={this.hide}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="social-icon id-icon id-icon--logo"></div>
              <div className="hat">{this.props.mobile || <img src="/images/hat.png" alt="" />}</div>
              <div className="modal-close" onClick={this.hide}></div>
              <div className="yourwork__title">
                <img src="images/modal/title5.png" alt="" />
              </div>
              <div className="modal-id-success__title">
                С этими регистрационными данными вы сможете <br /> авторизовываться на любых проектах Happy.
              </div>

              <div className="share">
                <div>
                  С друзьями всегда веселее. <br />
                  Не забудьте поделиться с ними ссылкой
                  <div className="social social2">
                    <a href="#" target="_blank" className="social-icon vk-icon2" />
                    <a href="#" target="_blank" className="social-icon fb-icon2" />
                    <a href="#" target="_blank" className="social-icon ok-icon2" />
                  </div>
                </div>
              </div>

              <div className="btn button5" onClick={this.hide}>
                <span>вперед</span>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  { closeSignUpSuccess }
)(SocialSuccess)
