import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'

import { closeAlmostComplite, openWhereDoYouWork, saveNotEmployee } from '../../redux/modules/modal'

class ModalAlmostComplite extends Component {
  state = {
    visible: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeAlmostComplite, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return (
      <div className={`modal modal-message almost-complete ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="hat">{this.props.mobile || <img src="/images/hat.png" alt="" />}</div>
              <div className="modal-content">
                <div className="almost-complete__title">
                  <img src="images/modal/title1.png" alt="" />
                </div>
                <div className="almost-complete__text">
                  Я - сотрудник <b>Х5</b>
                </div>
                <div className="almost-complete__btn">
                  <div className="btn button5" onClick={() => this.handleClick(1)}>
                    <span>Да</span>
                  </div>
                  <div className="btn button5 btn5-2" onClick={() => this.handleClick(0)}>
                    <span>Нет</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleClick = (employee) => {
    this.hide()
    if (employee === 1) return this.props.openWhereDoYouWork()

    const formData = new FormData()
    formData.append('UF_EMPLOYEE', 0)
    formData.append('UF_SOCSERV_FINISHED', 1)
    this.props.saveNotEmployee(formData)
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  { closeAlmostComplite, openWhereDoYouWork, saveNotEmployee }
)(ModalAlmostComplite)
