// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'

/**
 * Constants
 * */
export const moduleName = 'first'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: ['/images/spacer.png'],
  loading: true
}

export default (state = defaultState, action) => {
  const { type } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}
