import React, { Component } from 'react'
import { connect } from 'react-redux'
import loadImage from 'blueimp-load-image'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'
import { getPlaceData } from '../../redux/modules/access'
import { closeSignUp, sendPhoto, sendSignUp } from '../../redux/modules/modal'

class ModalSignUp extends Component {
  state = {
    triggerValidate: false,
    fields: {
      'REGISTER[EMAIL]': {
        value: '',
        name: 'REGISTER[EMAIL]',
        type: 'name',
        placeholder: 'Логин (e-mail)',
        validate: 'email',
        valid: false
      },
      'REGISTER[PASSWORD]': {
        value: '',
        name: 'REGISTER[PASSWORD]',
        type: 'password',
        placeholder: 'Пароль',
        validate: 'password',
        valid: false
      },
      'REGISTER[CONFIRM_PASSWORD]': {
        value: '',
        name: 'REGISTER[CONFIRM_PASSWORD]',
        type: 'password',
        placeholder: 'Подтверждение пароля',
        validate: 'password',
        valid: false,
        compare: 'REGISTER[PASSWORD]'
      },
      'REGISTER[NAME]': {
        value: '',
        name: 'REGISTER[NAME]',
        type: 'text',
        placeholder: 'Имя',
        validate: 'text',
        valid: false
      },
      'REGISTER[LAST_NAME]': {
        value: '',
        name: 'REGISTER[LAST_NAME]',
        type: 'text',
        placeholder: 'Фамилия',
        validate: 'text',
        valid: false
      }
    },
    fields2: {
      UF_NET: {
        value: '',
        name: 'UF_NET',
        placeholder: 'Принадлежность к сетям',
        valid: false
      },
      'REGISTER[WORK_POSITION]': {
        value: '',
        name: 'REGISTER[WORK_POSITION]',
        type: 'text',
        placeholder: 'Должность',
        validate: 'text',
        valid: false
      },
      UF_REGION: {
        value: '',
        name: 'UF_REGION',
        placeholder: 'Регион',
        valid: false
      },
      'REGISTER[WORK_CITY]': {
        value: '',
        name: 'REGISTER[WORK_CITY]',
        type: 'text',
        placeholder: 'Город',
        validate: 'text',
        valid: false
      },
      UF_WORK_PLACE: {
        value: '',
        name: 'UF_WORK_PLACE',
        type: 'text',
        placeholder: 'Магазин/офис',
        validate: 'text',
        valid: false
      }
    },
    REGISTER_FILES_PERSONAL_PHOTO: null,
    PERSONAL_PHOTO_DATA: null,
    type: 1,
    visible: false,
    pending: false
  }

  id = null

  componentDidMount() {
    if (!this.props.ufNetOptions.length) this.props.getPlaceData()
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const { success, error } = this.props
    if ((success && prevProps.success !== success) || (error && prevProps.error !== error)) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeSignUp, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    //const url = this.state.REGISTER_FILES_PERSONAL_PHOTO ? window.URL.createObjectURL(this.state.REGISTER_FILES_PERSONAL_PHOTO) : ''
    //const url = this.state.REGISTER_FILES_PERSONAL_PHOTO
    const url = this.state.PERSONAL_PHOTO_DATA

    console.log(this.state.triggerValidate)

    return (
      <div className={`modal happy-id ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              {this.state.type === 1 && (
                <div className="m1">
                  <img src="/images/m1.png" alt="" />
                </div>
              )}
              {this.state.type === 1 && (
                <div className="m2">
                  <img src="/images/m2.png" alt="" />
                </div>
              )}
              <div className="modal-close" onClick={this.hide}></div>
              <div className="happy-id__title">
                <img src="images/modal/title2.png" alt="" />
              </div>

              <div className="happy-id__form">
                <div>
                  <InputField
                    triggerValidate={this.state.triggerValidate}
                    setFieldValid={this.setFieldValid}
                    change={this.handleChange}
                    compareValue={this.state.fields['REGISTER[EMAIL]'].compare ? this.state.fields[this.state.fields['REGISTER[EMAIL]'].compare].value : ''}
                    {...this.state.fields['REGISTER[EMAIL]']}
                  />
                  <InputField
                    triggerValidate={this.state.triggerValidate}
                    setFieldValid={this.setFieldValid}
                    change={this.handleChange}
                    compareValue={this.state.fields['REGISTER[PASSWORD]'].compare ? this.state.fields[this.state.fields['REGISTER[PASSWORD]'].compare].value : ''}
                    {...this.state.fields['REGISTER[PASSWORD]']}
                  />
                  <InputField
                    triggerValidate={this.state.triggerValidate}
                    setFieldValid={this.setFieldValid}
                    change={this.handleChange}
                    compareValue={this.state.fields['REGISTER[CONFIRM_PASSWORD]'].compare ? this.state.fields[this.state.fields['REGISTER[CONFIRM_PASSWORD]'].compare].value : ''}
                    {...this.state.fields['REGISTER[CONFIRM_PASSWORD]']}
                  />
                  <InputField
                    triggerValidate={this.state.triggerValidate}
                    setFieldValid={this.setFieldValid}
                    change={this.handleChange}
                    compareValue={this.state.fields['REGISTER[NAME]'].compare ? this.state.fields[this.state.fields['REGISTER[NAME]'].compare].value : ''}
                    {...this.state.fields['REGISTER[NAME]']}
                  />
                  <InputField
                    triggerValidate={this.state.triggerValidate}
                    setFieldValid={this.setFieldValid}
                    change={this.handleChange}
                    compareValue={this.state.fields['REGISTER[LAST_NAME]'].compare ? this.state.fields[this.state.fields['REGISTER[LAST_NAME]'].compare].value : ''}
                    {...this.state.fields['REGISTER[LAST_NAME]']}
                  />
                </div>
                <div className="file-to-upload">
                  <input accept="image/gif, image/jpeg, image/png" type="file" onChange={this.fileChange} />
                  Ваш аватар <br />
                  <i>Выбрать файл</i>
                  <span className="file-to-upload__photo">
                    <span style={url ? { backgroundColor: '#000', backgroundImage: `url(${url})` } : {}}></span>
                  </span>
                  <span>
                    <img src="/images/modal/photo-layer.png" alt="" />
                  </span>
                </div>
              </div>
              <div className="sign-up__text2">
                <div className="modal-hr modal-hr--1"></div>
                <div className="sign-up__text2__in">
                  <div className="checkbox-title">Работаю в Х5 Retail Group:</div>
                  <div className="field-double">
                    <div className="input-field check-field">
                      <label>
                        <input type="radio" value="1" name="type" onChange={this.handleChangeCheckbox} checked={this.state.type === 1} />
                        <i></i>
                        <span>Да</span>
                      </label>
                    </div>
                    <div className="input-field check-field">
                      <label>
                        <input type="radio" value="0" name="type" onChange={this.handleChangeCheckbox} checked={this.state.type === 0} />
                        <i></i>
                        <span>Нет</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-hr modal-hr--2"></div>
              </div>
              {this.state.type === 1 && (
                <div className="happy-id__form happy-id__form2">
                  <div>
                    <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2.UF_NET} options={this.ufNetOptions} />
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2['REGISTER[WORK_POSITION]']} />
                    <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2.UF_REGION} options={this.ufRegionOptions} />
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2['REGISTER[WORK_CITY]']} />
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2.UF_WORK_PLACE} />
                  </div>
                  <div></div>
                </div>
              )}
              <div className={this.state.type === 1 ? `sign-up__text` : `sign-up__text sign-up__text--2`}>
                <div className="modal-hr modal-hr--2"></div>
                {/*При входе или регистрации вы соглашаетесь с{' '}
                <a href="#" target="_blank">
                  правилами
                </a>*/}
              </div>
              <div onClick={this.handleSend} className="btn hid_btn">
                <span>
                  получить happy<i>id</i>
                </span>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get ufNetOptions() {
    const options = this.props.ufNetOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.fields2.UF_NET.placeholder}
      </option>,
      ...options
    ]
  }

  get ufRegionOptions() {
    const options = this.props.ufRegionOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.fields2.UF_REGION.placeholder}
      </option>,
      ...options
    ]
  }

  fields() {
    const { fields } = this.state
    return Object.keys(fields).map((el, i) => {
      console.log(fields)
      const field = fields[el]
      return <InputField compareValue={field.compare ? fields[field.compare].value : ''} key={i} triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...field} />
    })
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending || (this.state.type === 2 && !this.formIsValid2())) return false
    this.setState({ pending: true })

    const formData = new FormData()
    Object.keys(this.state.fields).map((el) => {
      const field = this.state.fields[el]
      formData.append(field.name, field.value)
    })

    formData.append('REGISTER[AUTO_TIME_ZONE]', 'Y')
    formData.append('register_submit_button', 'Y')
    formData.append('UF_EMPLOYEE', this.state.type)
    formData.append('UF_SOCSERV_FINISHED', 0)

    if (this.state.type === 1) {
      Object.keys(this.state.fields2).map((el) => {
        const field = this.state.fields2[el]
        formData.append(field.name, field.value)
      })
    }

    //formData.append('REGISTER_FILES_PERSONAL_PHOTO', this.state.REGISTER_FILES_PERSONAL_PHOTO)
    formData.append('PERSONAL_PHOTO_DATA', this.state.PERSONAL_PHOTO_DATA)

    // const config = { headers: { 'content-type': 'multipart/form-data' } }

    this.props.sendSignUp(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state.fields).every((name) => {
      const field = this.state.fields[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  formIsValid2 = () => {
    return Object.keys(this.state.fields2).every((name) => {
      const field = this.state.fields2[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: { ...this.state.fields[name], valid }
      }
    })

  setFieldValid2 = (name, valid) =>
    this.setState({
      fields2: {
        ...this.state.fields2,
        [name]: { ...this.state.fields2[name], valid }
      }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      fields: { ...this.state.fields, [name]: { ...this.state.fields[name], value } }
    })
  }

  handleChange2 = (ev) => {
    const { name, value } = ev.target
    this.setState({
      fields2: { ...this.state.fields2, [name]: { ...this.state.fields2[name], value } }
    })
  }

  handleChangeCheckbox = (ev) => {
    const { name, value } = ev.target
    this.setState({ [name]: +value })
  }

  fileChange = (e) => {
    const file = e.target.files[0]
    const imageType = /image.*/
    if (file.type.match(imageType)) {
      loadImage(
        e.target.files[0],
        (img) => {
          //this.setState({ REGISTER_FILES_PERSONAL_PHOTO: img.toDataURL() })
          this.setState({ PERSONAL_PHOTO_DATA: img.toDataURL() })
        },
        {
          maxWidth: 650,
          orientation: true,
          canvas: true
        }
      )
    }
  }
}

export default connect(
  (state) => ({
    ufNetOptions: state.access.place_data.net,
    ufRegionOptions: state.access.place_data.region,
    success: state.modal.sign_up.success
  }),
  { closeSignUp, sendPhoto, sendSignUp, getPlaceData }
)(ModalSignUp)
