import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

class PrivateRoute extends Component {
  render() {
    const { component: Component, userIsAuthorized, ...rest } = this.props

    return <Route {...rest} render={(props) => (userIsAuthorized ? <Component {...props} /> : <Redirect to="/" />)} />
  }
}

export default connect(
  (state) => ({
    userIsAuthorized: state.access.userIsAuthorized
  }),
  {}
)(PrivateRoute)
