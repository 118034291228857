import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import InputField from '../common/input-field'
import { setSuccess } from '../../redux/modules/modal'

class Modal extends Component {
  static propTypes = {
    success: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    send: PropTypes.func.isRequired,
    title: PropTypes.string,
    getNext: PropTypes.func.isRequired
  }

  state = {
    triggerValidate: false,
    fields: {
      email: {
        value: '',
        name: 'email',
        type: 'name',
        placeholder: 'e-mail коллеги',
        validate: 'email',
        valid: false
      },
      email2: {
        value: '',
        name: 'email2',
        type: 'name',
        placeholder: 'e-mail коллеги',
        validate: 'email2',
        valid: true
      }
    },
    visible: false,
    pending: false
  }

  id = null

  categories = {
    1: 'самого сильного',
    2: 'самого умного',
    3: 'самого храброго',
    4: 'самого проницательного',
    5: 'самого находчивого'
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
    document.body.style.overflowY = 'hidden'
  }

  componentDidUpdate(prevProps, prevState) {
    const { success, error } = this.props
    if ((success && prevProps.success !== success) || (error && prevProps.error !== error)) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.close, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
    document.body.style.overflowY = 'visible'
  }

  render() {
    const { category, hidebtn } = this.props
    return (
      <div className={`modal ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay" onClick={this.hide}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="modal-agent">
                <img src="/images/modal-agent.png" alt="" />
              </div>
              <div className="modal-close ico-close" onClick={this.hide}></div>
              <div className="modal-title">Помоги агенту. Позови на помощь</div>
              <div className="modal-title2">{this.categories[category]}</div>
              <div className="modal-title3">коллегу</div>
              <div className="modal-info">
                Чем больше вы позовёте коллег, тем быстрее
                <br />
                придёт помощь и будет выше ваш <Link to="/ratings">итоговый рейтинг</Link>
              </div>
              <div className="modal-content">
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.fields.email} />
                <div className="modal-hr">
                  Одного будет мало? <br /> Давайте позовем еще
                </div>
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.fields.email2} />
                {this.fields}
                <div onClick={this.handleAddField} className="modal-add-field">
                  <span>еще одного?</span>
                </div>
              </div>
              <div className="btn-field-wrap">
                {hidebtn || (
                  <div className="btn-field first" onClick={this.handleNext}>
                    <div className="btn button5">
                      <span>справлюсь сам</span>
                    </div>
                  </div>
                )}
                <div className="btn-field" onClick={this.handleSend}>
                  <div className="btn button5">
                    <span>позвать</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get fields() {
    const { fields } = this.state
    return Object.keys(fields).map((el, i) => {
      if (i > 1) return <InputField key={i} triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...fields[el]} />
      return null
    })
  }

  handleAddField = () => {
    const index = Object.keys(this.state.fields).length + 1
    this.setState({
      fields: {
        ...this.state.fields,
        [`email${index}`]: {
          value: '',
          name: `email${index}`,
          type: 'name',
          placeholder: 'e-mail коллеги',
          validate: 'email2',
          valid: true
        }
      }
    })
  }

  handleNext = () => {
    this.hide()
    this.props.setSuccess()
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid()) return false
    if (this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    Object.keys(this.state.fields).map((el) => {
      const val = this.state.fields[el].value
      if (val) formData.append('email[]', val)
    })
    formData.append('category', this.props.category)

    await this.props.send(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state.fields).every((name) => {
      const field = this.state.fields[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: { ...this.state.fields[name], valid }
      }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      fields: { ...this.state.fields, [name]: { ...this.state.fields[name], value } }
    })
  }
}

export default connect(null, { setSuccess })(Modal)
