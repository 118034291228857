import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'

import { loadingSelector, imagesSelector, loadingSuccess, getPage } from '../../redux/modules/level2'
import { openModal, closeModal, messageSelector, errorSelector, successSelector, openSelector, getNextSelector, sendForm, closeSuccess, closeError } from '../../redux/modules/modal'
import CanvasAnimation from '../common/canvas-animation'
import Icons from './icons'
import { setLevel } from '../../redux/modules/access'

import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'

class Level2 extends Component {
  state = {
    x: 0,
    open: true,
    step: 1
  }

  id = null
  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />
    return (
      <Fragment>
        <Header style={3} />
        <div className="level2 page fade-in" onMouseMove={this.handleMouseMove}>
          <div className={`content step${this.state.step}`}>
            <div className="level2__bg" style={{ transform: `translateX(${this.state.x / -2}%)` }}></div>
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />
              <div className="level2__agent3">
                <img src="/images/level2/agent3.png" alt="" />
              </div>
            </div>
            <div className="spacer spacer--center">
              <div className="level2__text">
                <img src="/images/level2/text.png" alt="" />
              </div>
              <img src="/images/spacer.png" alt="" />
              <div className="level2__door">
                <img src="/images/level2/door.png" alt="" />
                <span>
                  <span></span>
                </span>
              </div>
              {this.state.step === 1 && (
                <div className="level2__path">
                  <img src="/images/level2/line.png" alt="" />
                  <img src="/images/level2/line2.svg" className="img4" alt="" />
                  <img src="/images/level2/line3.svg" className="img5" alt="" />
                  <img src="/images/level2/line4.svg" className="img6" alt="" />
                </div>
              )}
              <div className="level2__bubble">
                <img src="/images/level2/bubble.png" alt="" />
                <img className="level2__bubble__text" src="/images/level2/text2.png" alt="" />
              </div>
              <div className="level2__stand">
                <img src="/images/level2/stand.png" alt="" />
              </div>

              <div className="level2__boom">
                <img src="/images/level2/boom.png" alt="" />
              </div>
              <div className="level2__device">
                <img src="/images/level2/device.png" alt="" />
              </div>
              <div className="level2__num">
                <img src="/images/level2/num.png" alt="" />
              </div>
              <div className="level2__controller">
                <CanvasAnimation src="/images/level2/controller.png" size={[110, 104]} fw={110} steps={3} speed={6} />
              </div>

              <div className="level2__agent">
                <img src="/images/level2/agent.png" alt="" />
              </div>
              <div className="btn-wrap">
                <div className="btn button3" onClick={this.props.openModal}>
                  <span>позвать</span>
                </div>
              </div>
              {this.state.step > 1 && (
                <div className="level2__agent2">
                  <img src="/images/level2/agent2.png" alt="" />
                </div>
              )}
              {this.state.step > 1 && (
                <div className="level2__bubble2">
                  <img src="/images/level2/bubble2.png" alt="" />
                  <img className="level2__bubble__text" src="/images/level2/text3.png" alt="" />
                </div>
              )}
              {this.state.step > 1 && (
                <div className="level2__bubble3">
                  <img src="/images/level2/bubble3.png" alt="" />
                  <img className="level2__bubble__text" src="/images/level2/text4.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && <Icons />}
              {this.state.step === 3 && (
                <div className="level1__a level1__a1">
                  <img src="/images/level2/a1.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__a level1__a2">
                  <img src="/images/level2/a2.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__a level1__a3">
                  <img src="/images/level2/a3.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level2__overlay">
                  <img src="/images/level2/overlay.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__bolt level1__bolt1">
                  <img src="/images/level2/bolt.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__bolt level1__bolt2">
                  <img src="/images/level2/bolt2.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__bolt level1__bolt3">
                  <img src="/images/level2/bolt3.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level1__bolt level1__bolt4">
                  <img src="/images/level2/bolt4.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level2__spin">
                  <img src="/images/level2/spin.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level2__spin2">
                  <img src="/images/level2/spin.png" alt="" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="level2__spin3">
                  <img src="/images/level2/spin.png" alt="" />
                </div>
              )}
              {this.state.step === 2 && (
                <div className="btn-wrap2">
                  <div className="btn button3" onClick={this.nextStep}>
                    <span>далее</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.open && <Modal getNext={this.nextStep} send={this.props.sendForm} close={this.props.closeModal} success={this.props.success} error={this.props.error} category="2" />}
        {this.props.error && <ErrorMessage close={this.props.closeError} message={this.props.message} />}
        {this.props.success && <SuccessMessage nextStep={this.nextStep} close={this.props.closeSuccess} />}
      </Fragment>
    )
  }

  componentDidUpdate(_, prevState) {
    if (this.state.step !== prevState.step && this.state.step === 3) {
      this.props.setLevel(3)
      this.id = setTimeout(() => {
        this.props.getPage('/level-3')
      }, 5000)
    }

    // if (this.state.step !== prevState.step && this.state.step === 2) {
    //   this.id = setTimeout(() => {
    //     this.nextStep()
    //   }, 4500)
    // }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),
    message: messageSelector(state),
    success: successSelector(state),
    error: errorSelector(state),
    open: openSelector(state)
  }),
  { loadingSuccess, getPage, setLevel, openModal, closeModal, sendForm, closeSuccess, closeError }
)(Level2)
