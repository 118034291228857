import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { openSocialSelector, closeSocialModal, openIdModal, openSignUp } from '../../redux/modules/modal'
import Social from '../common/social'

class ModalSocial extends Component {
  static propTypes = {
    closeSocialModal: PropTypes.func.isRequired
  }

  state = {
    visible: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeSocialModal, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return (
      <div className={`modal modal-message modal-social ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay" onClick={this.hide}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="hat">{this.props.mobile || <img src="/images/hat.png" alt="" />}</div>
              <div className="modal-close ico-close" onClick={this.hide}></div>
              <div className="modal-content">
                <div className="modal-title7">Чтобы стать</div>
                <div className="modal-title8">агентом Х5,</div>
                <div className="modal-title9">
                  {' '}
                  вам нужно авторизоваться любым <br /> из предложенных способов
                </div>

                <Social />
                <div className="modal-info3">
                  <b>HappyID</b> - это ваша сохраненная <br />
                  регистрация из прошлых проектов Happy
                  <br />
                  или
                  <br />
                  <a href="#" onClick={this.handleSignUp}>
                    зарегистрироваться
                  </a>
                </div>
                <div className="btn-field" onClick={this.handleSignUp}>
                  <div className="btn button5">
                    <span>
                      cоздать happy<i>id</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleSignUp = (e) => {
    e.preventDefault()
    this.hide()
    this.props.openSignUp()
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  { closeSocialModal, openIdModal, openSignUp }
)(ModalSocial)
