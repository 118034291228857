// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level2'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/level2/door.png',
    '/images/level2/bubble.png',
    '/images/level2/text2.png',
    '/images/level2/stand.png',
    '/images/level2/text.png',
    '/images/level2/boom.png',
    '/images/level2/device.png',
    '/images/level2/num.png',
    '/images/level2/controller.png',
    '/images/level2/agent.png',
    '/images/level2/agent2.png',
    '/images/level2/agent3.png',
    '/images/level2/bubble2.png',
    '/images/level2/text3.png',
    '/images/level2/bubble3.png',
    '/images/level2/text4.png',
    '/images/level2/overlay.png',
    '/images/level2/bolt.png',
    '/images/level2/bolt2.png',
    '/images/level2/bolt3.png',
    '/images/level2/bolt4.png',
    '/images/level2/spin.png',
    '/images/level2/bg.png',
    '/images/level2/bg2.jpg',
    '/images/level2/bg.jpg',
    '/images/level2/overlay_line.png',
    '/images/level2/a1.png',
    '/images/level2/a2.png',
    '/images/level2/a3.png'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading

/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
