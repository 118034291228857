// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level5'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/level5/bg.jpg',
    '/images/level5/bubble.png',
    '/images/level5/bubble-arrow.png',
    '/images/level5/megatron-shadow.png',
    '/images/level5/megatron.png',
    '/images/level5/guys.png',
    '/images/level5/bubble2.png',
    '/images/level5/btn.svg',

    '/images/level5/agent.png',
    '/images/level5/bubble3.png',
    '/images/level5/fire.png',
    '/images/level5/fire2.png',
    '/images/level5/fire3.png',
    '/images/level5/bg.png',
    '/images/level5/eye.svg',
    '/images/level5/eye-in.svg',
    '/images/level5/eyebrow1.svg',
    '/images/level5/eyebrow2.svg',

    '/images/level5/bg2.png',
    '/images/level5/megatron2.png',
    '/images/level5/smoke.png',
    '/images/level5/pc.png',
    '/images/level5/bubble4.png',
    '/images/level5/guys2.png',
    '/images/level5/screen.png',
    '/images/level5/screen1.png',
    '/images/level5/screen2.png',
    '/images/level5/screen3.png',
    '/images/level5/screen4.png',
    '/images/level5/screen5.png',
    '/images/level5/nums.png',
    '/images/level5/hand.png',
    '/images/level5/spl.png',
    '/images/level5/bubble5.png',
    '/images/level5/x8.png',
    '/images/level5/x8-thinks.png',
    '/images/level5/bubble6.png',

    '/images/level5/bg3.jpg',
    '/images/level5/bubble7.png',
    '/images/level5/bubble8.png',
    '/images/level5/agent2.png',

    '/images/level5/pc2.png',
    '/images/level5/earth.png',
    '/images/level5/fire4.png',
    '/images/level5/bubble9.png',
    '/images/level5/bg4.jpg'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
export const openSelector = (state) => state[moduleName].modal.open
export const successSelector = (state) => state[moduleName].modal.success
export const errorSelector = (state) => state[moduleName].modal.error
export const messageSelector = (state) => state[moduleName].modal.message
export const getNextSelector = (state) => state[moduleName].modal.getNext
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
