// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level5'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
/**
 * Reducer
 * */
const defaultState = {
  // images: ['/images/spacer.png', '/images/finish/text1.png', '/images/finish/text2.png', '/images/finish/text3.png', '/images/finish/btn.svg'],
  images: [
    '/images/spacer.png',
    '/images/finish2/bg.jpg',
    '/images/finish2/fire.png',
    '/images/finish2/step1/1.png',
    '/images/finish2/step1/2.png',
    '/images/finish2/step1/3.png',
    '/images/finish2/step1/4.png',
    '/images/finish2/step2/1.png',
    '/images/finish2/step2/2.png',
    '/images/finish2/step2/3.png',
    '/images/finish2/step2/4.png',
    '/images/finish2/step2/5.png',
    '/images/finish2/step2/6.png',
    '/images/finish2/step2/fire.png',
    '/images/finish2/step2/bubble.png',
    '/images/finish2/step2/guys.png',
    '/images/finish2/step1/floor.png',
    '/images/finish2/step1/man.png',
    '/images/finish2/step1/bird.png',
    '/images/finish2/step1/bird2.png',
    '/images/finish2/step1/bird3.png',
    '/images/finish2/step1/text.png',

    '/images/finish2/step3/btn.png',
    '/images/finish2/step3/flea.png',
    '/images/finish2/step3/bubble4.png',
    '/images/finish2/step3/2.png',
    '/images/finish2/step3/bubble3.png',
    '/images/finish2/step3/1.png',
    '/images/finish2/step3/bubble.png',
    '/images/finish2/step3/cat.png'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
export const openSelector = (state) => state[moduleName].modal.open
export const successSelector = (state) => state[moduleName].modal.success
export const errorSelector = (state) => state[moduleName].modal.error
export const messageSelector = (state) => state[moduleName].modal.message
export const getNextSelector = (state) => state[moduleName].modal.getNext
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}
