import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'
import { getPlaceData } from '../../redux/modules/access'
import { closeSocialSuccess } from '../../redux/modules/modal'

class SocialSuccess extends Component {
  state = {
    visible: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const { success } = this.props
    if (success && prevProps.success !== success) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeSocialSuccess, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    const { category } = this.props
    const url = this.state.PERSONAL_PHOTO ? window.URL.createObjectURL(this.state.PERSONAL_PHOTO) : ''

    return (
      <div className={`modal modal-message yourwork ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay" onClick={this.hide}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="hat">{this.props.mobile || <img src="/images/hat.png" alt="" />}</div>
              <div className="modal-close" onClick={this.hide}></div>
              <div className="yourwork__title">
                <img src="/images/modal/title4.png" alt="" />
              </div>
              <div className="share">
                <div>
                  С друзьями всегда веселее. <br />
                  Не забудьте поделиться с ними ссылкой
                  <div className="social social2">
                    <a href={`http://vkontakte.ru/share.php?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon vk-icon" />
                    <a href={`http://www.facebook.com/sharer.php?s=100&p[url]=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon fb-icon" />
                    <a href={`https://connect.ok.ru/offer?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon ok-icon" />
                  </div>
                </div>
              </div>

              <div className="btn button5" onClick={this.hide}>
                <span>вперед</span>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  { closeSocialSuccess }
)(SocialSuccess)
