import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './reducer'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import history from './history'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancer(applyMiddleware(thunk, routerMiddleware(history)))

const store = createStore(connectRouter(history)(reducer), enhancer)

window.store = store

export default store
