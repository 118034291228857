import React, { Component, Fragment } from 'react'
import { getRandom } from '../../services/utils'

class Icons extends Component {
  state = {
    icons: [],
    index: 21
  }

  id = null
  id2 = null

  componentDidMount() {
    setTimeout(() => {
      this.create(1)
      this.create(2)
      this.create(3)
      this.create(4)
      this.create(5)
      this.create(6)
      this.create(7)
      this.create(8)
      this.create(9)
      this.create(10)
    }, 10)
    this.id = setInterval(() => {
      this.create(1)
      this.create(2)
      this.create(3)
      this.create(4)
      this.create(5)
      this.create(6)
      this.create(7)
      this.create(8)
      this.create(9)
      this.create(10)
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.id)
    clearTimeout(this.id2)
  }

  render() {
    return <div className="level4__icons">{this.state.icons}</div>
  }

  create = (anim) => {
    const num = anim ? anim : getRandom(10, 1)
    this.setState({
      icons: [
        ...this.state.icons,
        <div key={this.state.index} className={`l4-icon2 l4-anim-${num} l4-color`}>
          <div></div>
        </div>
      ],
      index: this.state.index + 1
    })
  }
}

export default Icons
