import React, { Component } from 'react'

export default class PureCanvas extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <canvas width={this.props.size[0]} height={this.props.size[1]} ref={(node) => (node ? this.props.contextRef(node.getContext('2d')) : null)} />
  }
}
