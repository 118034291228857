import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class Counter extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired
  }

  state = {
    counter: 0,
    interval: 50,
    next: false // fire only
  }

  id = null

  componentDidMount() {
    this.id = setInterval(() => this.setState({ counter: this.state.counter + 1 }), 50)
  }

  componentDidUpdate(_, prevState) {
    if (this.state.counter === 99 && prevState.counter !== this.state.counter) {
      clearInterval(this.id)
      this.setState({ counter: 100 })
      if (!this.props.fire) this.props.callback()
    }

    if (this.props.fire && this.state.next && this.state.next !== prevState.next) {
      this.props.callback()
    }

    if (prevState.interval !== this.state.interval) {
      clearInterval(this.id)
      if (this.state.counter > 99) return false
      this.id = setInterval(() => this.setState({ counter: this.state.counter + 1 }), this.state.interval)
    }
  }

  speedUp = () => {
    this.setState({ interval: this.state.interval / 2 })
  }

  speedUp2 = () => {
    this.setState({ interval: 10, next: true })
  }

  componentWillUnmount() {
    clearInterval(this.id)
  }

  render() {
    const { counter, interval } = this.state
    return (
      <Fragment>
        <div className={`spinner spinner_${counter}`}>
          <svg viewBox="0 0 1169 1169">
            <g stroke="none" fill="none">
              <g id="spinner" fill="#000000" fillRule="nonzero">
                <path d="M565.32,0 L603.53,0 C603.52,16.79 603.56,33.57 603.52,50.36 C590.79,49.91 578.06,49.91 565.33,50.33 C565.31,33.55 565.33,16.78 565.32,0 Z" id="path1"></path>
                <path d="M626.48,1.25 C639.15,2.55 651.81,3.9 664.47,5.23 C662.73,21.91 660.99,38.58 659.21,55.25 C646.6,53.49 633.93,52.18 621.23,51.29 C622.97,34.6 624.73,17.93 626.48,1.25 Z" id="path2"></path>
                <path d="M687.18,8.87 C699.63,11.5 712.09,14.14 724.54,16.81 C721.07,33.19 717.59,49.57 714.09,65.95 C701.73,62.88 689.27,60.24 676.73,58.02 C680.2,41.63 683.68,25.25 687.18,8.87 Z" id="path3"></path>
                <path d="M731.23,70.54 C736.39,54.62 741.55,38.7 746.75,22.8 C758.86,26.71 770.95,30.66 783.06,34.59 C777.92,50.52 772.73,66.43 767.55,82.35 C755.58,78 743.47,74.05 731.23,70.54 Z" id="path4"></path>
                <path d="M804.51,42.89 C816.16,48.01 827.79,53.22 839.42,58.41 C832.6,73.71 825.82,89.02 818.98,104.3 C807.52,98.73 795.9,93.51 784.1,88.73 C790.89,73.44 797.7,58.17 804.51,42.89 Z" id="path5"></path>
                <path d="M834.77,112.32 C843.18,97.86 851.48,83.34 859.89,68.88 C870.93,75.23 881.95,81.6 892.97,87.97 C884.59,102.5 876.21,117.02 867.81,131.53 C857.03,124.73 845.94,118.44 834.77,112.32 Z" id="path6"></path>
                <path d="M882.68,141.21 C892.51,127.63 902.37,114.07 912.24,100.53 C922.53,108.01 932.84,115.48 943.13,122.98 C933.27,136.56 923.42,150.15 913.53,163.71 C903.52,155.84 893.21,148.36 882.68,141.21 Z" id="path7"></path>
                <path d="M927.3,174.87 C938.52,162.41 949.73,149.93 960.98,137.49 C970.45,145.99 979.91,154.51 989.36,163.04 C978.14,175.52 966.9,188 955.66,200.46 C946.5,191.62 937.03,183.08 927.3,174.87 Z" id="path8"></path>
                <path d="M968.18,213.01 C980.64,201.77 993.11,190.54 1005.6,179.32 C1014.12,188.78 1022.65,198.23 1031.15,207.71 C1018.69,218.95 1006.22,230.18 993.74,241.4 C985.53,231.65 977.02,222.17 968.18,213.01 Z" id="path9"></path>
                <path d="M1004.89,255.17 C1018.45,245.3 1032.02,235.44 1045.6,225.6 C1053.09,235.89 1060.57,246.19 1068.05,256.5 C1054.49,266.37 1040.92,276.23 1027.34,286.07 C1020.22,275.51 1012.72,265.22 1004.89,255.17 Z" id="path10"></path>
                <path d="M1037.01,300.93 C1051.51,292.55 1066.02,284.16 1080.54,275.81 C1086.92,286.82 1093.28,297.85 1099.64,308.88 C1085.14,317.27 1070.63,325.64 1056.12,334.01 C1050.12,322.77 1043.75,311.74 1037.01,300.93 Z" id="path11"></path>
                <path d="M1064.16,349.81 C1079.45,342.99 1094.74,336.18 1110.04,329.38 C1115.24,341.01 1120.4,352.64 1125.58,364.28 C1110.29,371.1 1095,377.91 1079.7,384.71 C1074.92,372.9 1069.73,361.26 1064.16,349.81 Z" id="path12"></path>
                <path d="M1086.05,401.26 C1101.96,396.1 1117.86,390.91 1133.78,385.76 C1137.74,397.86 1141.66,409.97 1145.59,422.08 C1129.67,427.28 1113.75,432.44 1097.83,437.6 C1094.31,425.36 1090.39,413.23 1086.05,401.26 Z" id="path13"></path>
                <path d="M1102.39,454.75 C1118.75,451.25 1135.13,447.78 1151.5,444.3 C1154.16,456.75 1156.8,469.21 1159.45,481.66 C1143.06,485.17 1126.66,488.65 1110.26,492.11 C1108.07,479.57 1105.44,467.11 1102.39,454.75 Z" id="path14"></path>
                <path d="M1113.02,509.63 C1129.68,507.88 1146.34,506.11 1163,504.38 C1164.33,517.05 1165.71,529.71 1166.98,542.39 C1150.29,544.11 1133.61,545.9 1116.93,547.63 C1116.06,534.92 1114.74,522.25 1113.02,509.63 Z" id="path15"></path>
                <path d="M1117.85,565.33 C1134.9,565.31 1151.95,565.32 1169,565.32 L1169,603.53 C1151.94,603.52 1134.88,603.55 1117.82,603.52 C1118.25,590.79 1118.24,578.05 1117.85,565.33 Z" id="path16"></path>
                <path d="M1116.88,621.23 C1133.56,622.96 1150.24,624.73 1166.92,626.48 C1165.61,639.15 1164.27,651.81 1162.93,664.48 C1146.26,662.73 1129.59,660.99 1112.92,659.21 C1114.67,646.6 1115.99,633.93 1116.88,621.23 Z" id="path17"></path>
                <path d="M1110.15,676.73 C1126.53,680.21 1142.92,683.68 1159.3,687.18 C1156.66,699.64 1154.02,712.09 1151.36,724.54 C1134.98,721.08 1118.6,717.59 1102.22,714.1 C1105.28,701.73 1107.93,689.27 1110.15,676.73 Z" id="path18"></path>
                <path d="M1097.62,731.24 C1113.55,736.38 1129.46,741.56 1145.37,746.75 C1141.46,758.86 1137.51,770.95 1133.58,783.06 C1117.65,777.94 1101.75,772.72 1085.83,767.57 C1090.13,755.58 1094.13,743.48 1097.62,731.24 Z" id="path19"></path>
                <path d="M1079.42,784.11 C1094.73,790.87 1109.99,797.72 1125.29,804.5 C1120.15,816.16 1114.94,827.78 1109.76,839.42 C1094.46,832.61 1079.15,825.81 1063.87,818.98 C1069.43,807.53 1074.66,795.92 1079.42,784.11 Z" id="path20"></path>
                <path d="M1036.64,867.81 C1043.4,857.02 1049.77,846 1055.79,834.78 C1070.3,843.12 1084.79,851.5 1099.28,859.88 C1092.94,870.92 1086.56,881.94 1080.19,892.97 C1065.67,884.59 1051.14,876.22 1036.64,867.81 Z" id="path21"></path>
                <path d="M1004.46,913.53 C1012.32,903.52 1019.8,893.21 1026.97,882.68 C1040.53,892.52 1054.09,902.37 1067.64,912.23 C1060.16,922.53 1052.69,932.84 1045.18,943.12 C1031.59,933.27 1018.02,923.41 1004.46,913.53 Z" id="path22"></path>
                <path d="M967.71,955.68 C976.53,946.49 985.09,937.05 993.29,927.3 C1005.76,938.52 1018.23,949.74 1030.69,960.98 C1022.18,970.44 1013.67,979.91 1005.13,989.35 C992.63,978.15 980.19,966.9 967.71,955.68 Z" id="path23"></path>
                <path d="M926.77,993.74 C936.51,985.53 945.99,977.02 955.16,968.18 C966.4,980.65 977.63,993.11 988.85,1005.59 C979.39,1014.12 969.94,1022.65 960.45,1031.15 C949.22,1018.68 937.98,1006.22 926.77,993.74 Z" id="path24"></path>
                <path d="M882.1,1027.35 C892.65,1020.22 902.94,1012.71 913,1004.9 C922.87,1018.46 932.74,1032.02 942.57,1045.61 C932.25,1053.07 921.98,1060.61 911.65,1068.04 C901.8,1054.47 891.93,1040.92 882.1,1027.35 Z" id="path25"></path>
                <path d="M834.16,1056.12 C845.39,1050.12 856.43,1043.75 867.24,1037.01 C875.61,1051.52 884.01,1066.02 892.36,1080.55 C881.35,1086.92 870.31,1093.26 859.31,1099.65 C850.88,1085.16 842.54,1070.63 834.16,1056.12 Z" id="path26"></path>
                <path d="M783.46,1079.7 C795.26,1074.91 806.91,1069.74 818.36,1064.16 C825.17,1079.45 831.99,1094.74 838.78,1110.04 C827.15,1115.23 815.52,1120.41 803.89,1125.58 C797.06,1110.29 790.26,1095 783.46,1079.7 Z" id="path27"></path>
                <path d="M730.56,1097.83 C742.8,1094.31 754.92,1090.4 766.89,1086.05 C772.09,1101.95 777.24,1117.87 782.41,1133.78 C770.31,1137.73 758.2,1141.65 746.09,1145.59 C740.89,1129.68 735.73,1113.75 730.56,1097.83 Z" id="path28"></path>
                <path d="M676.05,1110.26 C688.59,1108.08 701.06,1105.44 713.42,1102.38 C716.91,1118.76 720.4,1135.13 723.86,1151.51 C711.41,1154.15 698.96,1156.81 686.51,1159.45 C682.99,1143.06 679.52,1126.66 676.05,1110.26 Z" id="path29"></path>
                <path d="M620.54,1118.93 C633.25,1118.06 645.92,1116.74 658.54,1115.02 C660.28,1131.68 662.05,1148.34 663.78,1165.01 C651.12,1166.35 638.46,1167.68 625.8,1169 C624.03,1152.31 622.27,1135.62 620.54,1118.93 Z" id="path30"></path>
                <path d="M564.65,1117.82 C577.37,1118.24 590.11,1118.26 602.83,1117.84 C602.85,1134.89 602.84,1151.94 602.84,1169 L564.64,1169 C564.64,1151.94 564.62,1134.88 564.65,1117.82 Z" id="path31"></path>
                <path d="M508.95,1112.92 C521.56,1114.67 534.23,1115.99 546.94,1116.89 C545.2,1133.57 543.43,1150.24 541.69,1166.92 C529.02,1165.61 516.35,1164.28 503.69,1162.93 C505.43,1146.26 507.18,1129.59 508.95,1112.92 Z" id="path32"></path>
                <path d="M443.63,1151.35 C447.11,1134.97 450.55,1118.58 454.09,1102.21 C466.44,1105.31 478.9,1107.93 491.44,1110.15 C487.96,1126.53 484.49,1142.92 480.98,1159.3 C468.53,1156.66 456.07,1154.03 443.63,1151.35 Z" id="path33"></path>
                <path d="M400.59,1085.87 C412.61,1090.09 424.68,1094.15 436.94,1097.63 C431.77,1113.54 426.62,1129.46 421.42,1145.37 C409.31,1141.46 397.21,1137.5 385.11,1133.59 C390.23,1117.67 395.43,1101.78 400.59,1085.87 Z" id="path34"></path>
                <path d="M349.19,1063.87 C360.64,1069.43 372.25,1074.66 384.06,1079.42 C377.28,1094.73 370.46,1110.01 363.64,1125.3 C352.01,1120.12 340.37,1114.96 328.75,1109.75 C335.55,1094.45 342.36,1079.15 349.19,1063.87 Z" id="path35"></path>
                <path d="M300.35,1036.64 C311.15,1043.39 322.17,1049.78 333.4,1055.79 C325.03,1070.29 316.67,1084.8 308.28,1099.28 C297.24,1092.94 286.23,1086.56 275.2,1080.2 C283.57,1065.67 291.95,1051.15 300.35,1036.64 Z" id="path36"></path>
                <path d="M225.04,1045.19 C234.88,1031.6 244.75,1018.03 254.62,1004.46 C264.65,1012.31 274.95,1019.8 285.48,1026.96 C275.66,1040.54 265.8,1054.09 255.94,1067.64 C245.63,1060.17 235.33,1052.67 225.04,1045.19 Z" id="path37"></path>
                <path d="M178.81,1005.13 C190.03,992.64 201.26,980.16 212.51,967.7 C221.67,976.55 231.13,985.08 240.87,993.29 C229.65,1005.76 218.43,1018.23 207.2,1030.69 C197.72,1022.18 188.26,1013.67 178.81,1005.13 Z" id="path38"></path>
                <path d="M137.01,960.46 C149.49,949.23 161.94,937.97 174.45,926.77 C182.63,936.53 191.16,945.99 199.98,955.16 C187.51,966.4 175.06,977.65 162.55,988.84 C154.05,979.37 145.51,969.93 137.01,960.46 Z" id="path39"></path>
                <path d="M100.12,911.66 C113.68,901.8 127.25,891.95 140.81,882.09 C147.95,892.64 155.44,902.94 163.27,912.99 C149.73,922.88 136.14,932.71 122.58,942.57 C115.08,932.28 107.59,921.97 100.12,911.66 Z" id="path40"></path>
                <path d="M68.53,859.28 C83.03,850.9 97.54,842.52 112.05,834.16 C118.04,845.39 124.41,856.42 131.16,867.22 C116.66,875.63 102.15,883.99 87.64,892.36 C81.24,881.35 74.89,870.31 68.53,859.28 Z" id="path41"></path>
                <path d="M42.6,803.89 C57.88,797.05 73.18,790.26 88.47,783.46 C93.24,795.27 98.45,806.9 104,818.37 C88.7,825.16 73.42,831.99 58.13,838.78 C52.93,827.16 47.75,815.53 42.6,803.89 Z" id="path42"></path>
                <path d="M22.58,746.09 C38.49,740.89 54.41,735.73 70.34,730.56 C73.85,742.8 77.78,754.92 82.11,766.89 C66.21,772.09 50.29,777.25 34.38,782.4 C30.44,770.3 26.5,758.19 22.58,746.09 Z" id="path43"></path>
                <path d="M8.72,686.51 C25.11,683 41.51,679.52 57.91,676.05 C60.09,688.59 62.73,701.06 65.78,713.42 C49.41,716.91 33.04,720.4 16.66,723.86 C14.01,711.41 11.36,698.96 8.72,686.51 Z" id="path44"></path>
                <path d="M1.17,625.79 C17.86,624.04 34.54,622.27 51.23,620.54 C52.12,633.24 53.41,645.92 55.15,658.54 C38.48,660.27 21.82,662.06 5.15,663.78 C3.84,651.11 2.47,638.46 1.17,625.79 Z" id="path45"></path>
                <path d="M0,564.64 C16.78,564.64 33.57,564.62 50.35,564.65 C49.92,577.37 49.91,590.11 50.33,602.84 C33.55,602.85 16.78,602.84 0,602.84 L0,564.64 Z" id="path46"></path>
                <path d="M5.23,503.69 C21.9,505.44 38.58,507.17 55.25,508.96 C53.5,521.57 52.16,534.23 51.29,546.93 C34.61,545.21 17.93,543.43 1.24,541.69 C2.55,529.02 3.9,516.35 5.23,503.69 Z" id="path47"></path>
                <path d="M16.81,443.63 C33.19,447.09 49.57,450.58 65.95,454.07 C62.87,466.43 60.24,478.89 58.02,491.43 C41.63,487.96 25.25,484.48 8.87,480.99 C11.49,468.53 14.15,456.08 16.81,443.63 Z" id="path48"></path>
                <path d="M22.83,421.45 C26.66,409.3 30.68,397.22 34.59,385.1 C50.52,390.26 66.44,395.42 82.35,400.62 C78,412.59 74.05,424.7 70.54,436.94 C54.63,431.79 38.73,426.61 22.83,421.45 Z" id="path49"></path>
                <path d="M58.41,328.75 C73.71,335.56 89.02,342.36 104.31,349.19 C98.72,360.63 93.52,372.26 88.73,384.06 C73.44,377.28 58.16,370.46 42.88,363.65 C48.02,352.01 53.22,340.38 58.41,328.75 Z" id="path50"></path>
                <path d="M68.9,308.3 C75.19,297.23 81.61,286.23 87.97,275.2 C102.5,283.57 117.01,291.96 131.53,300.34 C124.77,311.14 118.4,322.17 112.37,333.4 C97.88,325.04 83.39,316.66 68.9,308.3 Z" id="path51"></path>
                <path d="M100.53,255.93 C108,245.62 115.49,235.33 122.98,225.03 C136.56,234.89 150.14,244.75 163.71,254.63 C155.85,264.65 148.36,274.95 141.2,285.48 C127.63,275.65 114.08,265.8 100.53,255.93 Z" id="path52"></path>
                <path d="M137.48,207.19 C145.98,197.71 154.5,188.26 163.04,178.81 C175.53,190.03 188.01,201.26 200.47,212.51 C191.61,221.66 183.08,231.13 174.88,240.87 C162.41,229.65 149.94,218.43 137.48,207.19 Z" id="path53"></path>
                <path d="M207.68,137.01 C218.96,149.45 230.16,161.95 241.4,174.42 C231.66,182.63 222.18,191.14 213.01,199.98 C201.76,187.52 190.54,175.05 179.32,162.57 C188.76,154.04 198.24,145.55 207.68,137.01 Z" id="path54"></path>
                <path d="M225.6,122.56 C235.9,115.08 246.2,107.6 256.49,100.12 C266.37,113.67 276.22,127.24 286.07,140.81 C275.52,147.95 265.22,155.45 255.17,163.27 C245.3,149.71 235.43,136.15 225.6,122.56 Z" id="path55"></path>
                <path d="M275.82,87.67 C286.78,81.2 297.86,74.92 308.88,68.53 C317.28,83.03 325.64,97.54 334.01,112.05 C322.77,118.04 311.75,124.42 300.94,131.15 C292.54,116.67 284.2,102.16 275.82,87.67 Z" id="path56"></path>
                <path d="M329.38,58.13 C341,52.93 352.64,47.75 364.28,42.59 C371.11,57.88 377.91,73.18 384.71,88.47 C372.89,93.25 361.24,98.43 349.79,104.03 C343.03,88.71 336.17,73.44 329.38,58.13 Z" id="path57"></path>
                <path d="M385.76,34.38 C397.86,30.43 409.97,26.5 422.08,22.58 C427.28,38.49 432.44,54.42 437.61,70.34 C425.36,73.84 413.24,77.79 401.26,82.11 C396.09,66.2 390.91,50.3 385.76,34.38 Z" id="path58"></path>
                <path d="M444.3,16.66 C456.76,14.01 469.21,11.35 481.67,8.72 C485.15,25.11 488.65,41.5 492.12,57.89 C479.57,60.1 467.11,62.73 454.74,65.78 C451.25,49.41 447.77,33.04 444.3,16.66 Z" id="path59"></path>
                <path d="M504.38,5.15 C517.04,3.83 529.71,2.48 542.37,1.17 C544.13,17.86 545.89,34.55 547.63,51.24 C534.94,52.12 522.28,53.39 509.69,55.16 C507.81,38.5 506.15,21.82 504.38,5.15 Z" id="path60"></path>
              </g>
            </g>
          </svg>
          <div className="orbit" style={{ transform: `rotate(${45 + 3.6 * counter}deg)`, transition: `${interval * 2}ms` }}>
            <div className="dot" />
          </div>
          <div className="counter">
            {this.props.fire && <div>лазер</div>}
            {this.state.counter}
            <span>%</span>
          </div>
        </div>
        {this.props.fire ? (
          <div className="btn-wrap">
            <div className="btn button5" onClick={this.speedUp2}>
              <span>огонь</span>
            </div>
          </div>
        ) : (
          <div className="btn-wrap">
            <div className="btn button5" onClick={this.speedUp}>
              <span>быстрее</span>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default Counter
