// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'

/**
 * Constants
 * */
export const moduleName = 'intro'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/intro/floor.png',
    '/images/intro/agent.png',
    '/images/intro/bubble.png',
    '/images/intro/bubble2.png',
    '/images/intro/wave2.png',
    '/images/intro/hand2.png',
    '/images/intro/text2.svg',
    '/images/smoke2.png',
    '/images/intro/grid-layer.png',
    '/images/intro/text1.png',
    '/images/intro/text2.png'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}
