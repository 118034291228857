import axios from 'axios'
import { api } from '../config'

/**
 * Constants
 * */
export const moduleName = 'access'
export const SET_LEVEL = `${moduleName}/SET_LEVEL`

export const GET_LINKS_SUCCESS = `${moduleName}/GET_LINKS_SUCCESS`

export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`

export const SUCCESS_PROFILE = `${moduleName}/SUCCESS_PROFILE`
export const ERROR_PROFILE = `${moduleName}/ERROR_PROFILE`

export const SOCIAL_AUTH_1 = `${moduleName}/LOGIN_STEP_1`
export const SOCIAL_AUTH_2 = `${moduleName}/LOGIN_STEP_2`
export const SOCIAL_AUTH_ERROR = `${moduleName}/LOGIN_STEP_ERROR`
export const CLOSE_SOCIAL_AUTH_ERROR = `${moduleName}/CLOSE_SOCIAL_AUTH_ERROR`

export const USER_AUTHORIZED = `${moduleName}/USER_AUTHORIZED`
export const USER_NOT_AUTHORIZED = `${moduleName}/USER_NOT_AUTHORIZED`

export const LOGOUT = `${moduleName}/LOGOUT`

export const GET_PLACE_DATA = `${moduleName}/GET_PLACE_DATA`

/**
 * Reducer
 * */
const initialLevel = window.localStorage.getItem('level')
const defaultState = {
  checkedAuthorized: false,
  userIsAuthorized: false,

  auth_step: null,
  level: initialLevel ? +initialLevel : -2,
  links: [],
  user: null,

  error_mesage: null,

  place_data: {
    net: [],
    region: []
  }
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_AUTHORIZED:
      return { ...state, userIsAuthorized: true, checkedAuthorized: true }

    case USER_NOT_AUTHORIZED:
      return { ...state, userIsAuthorized: false, checkedAuthorized: true }

    case SET_LEVEL:
      if (payload.level > defaultState.level) window.localStorage.setItem('level', payload.level)
      return { ...state, level: payload.level }

    case GET_LINKS_SUCCESS:
      return { ...state, links: payload.links }

    case SUCCESS_PROFILE:
      return { ...state, user: payload.user }

    case ERROR_PROFILE:
      return { ...state, error_mesage: payload.message }

    case CLOSE_ERROR:
      return { ...state, error_mesage: null }

    case LOGOUT:
      return { ...state, userIsAuthorized: false, user: null }

    case GET_PLACE_DATA:
      return { ...state, place_data: payload.place_data }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const stepSelector = (state) => state[moduleName].step
export const profileSelector = (state) => state[moduleName].user
export const userIdSelector = (state) => state[moduleName].userId

export const linksSelector = (state) => state[moduleName].links

/**
 * Action Creators
 * */

export const userIsAuthorized = () => async (dispatch) => {
  const { data } = await axios.post(api + 'user-profile.php')
  if (!data.authorized) dispatch({ type: USER_NOT_AUTHORIZED })
  if (data.authorized) {
    await dispatch({ type: USER_AUTHORIZED })
    dispatch(getProfile())
  }
}

export const setLevel = (level) => ({ type: SET_LEVEL, payload: { level } })

export const getLinks = () => async (dispatch) => {
  const { data } = await axios.post(api + 'socauth-json.php', null, { params: { mode: 'url' } })
  dispatch({ type: GET_LINKS_SUCCESS, payload: { links: data } })
}

export const getProfile = () => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', null, { params: { service: 'user', method: 'profileselfget' } })
  if (data.errorCode) return dispatch({ type: ERROR_PROFILE, payload: { message: data.errorMessage } })
  dispatch({ type: SUCCESS_PROFILE, payload: { user: data.profile } })
}
export const closeError = () => ({ type: CLOSE_ERROR })

export const logOut = () => async (dispatch) => {
  const { data } = await axios.post(api + 'user-logout.php')
  await dispatch({ type: LOGOUT })
  if (window) window.location.reload()
}
export const getPlaceData = () => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', null, { params: { service: 'dictionary', method: 'dictionariesget' } })
  dispatch({ type: GET_PLACE_DATA, payload: { place_data: data } })
}

// вызываются внутри окна авторизации через соц сети
// window.parent.store.dispatch({type: 'access/LOGIN_STEP_1'})
// window.parent.store.dispatch({type: 'access/LOGIN_STEP_2'})
// window.parent.store.dispatch({type: 'access/LOGIN_STEP_ERROR'})
