import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'

import { closeIdModal, signInByID, openSignUp, openForgotPassword } from '../../redux/modules/modal'

class ModalId extends Component {
  state = {
    triggerValidate: false,
    email: {
      value: '',
      name: 'email',
      type: 'name',
      // placeholder: 'Логин (E-mail)',
      placeholder: 'Логин',
      validate: 'text',
      valid: false
    },
    password: {
      value: '',
      name: 'password',
      type: 'password',
      placeholder: 'Пароль',
      validate: 'password',
      valid: false
    },
    visible: false,
    pending: false
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeIdModal, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return (
      <div className={`modal modal-message modal-id ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay" onClick={this.hide}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="social-icon id-icon id-icon--logo"></div>
              <div className="icon-id"></div>
              <div className="hand-id"></div>
              <div className="modal-close ico-close" onClick={this.hide}></div>
              <div className="modal-content">
                <div className="modal-title10">Авторизоваться </div>
                <div className="modal-title11">
                  через{' '}
                  <span>
                    Happy<i>ID</i>
                  </span>
                </div>
                <div className="modal-title12">
                  {' '}
                  Введите свои данные, указанные <br /> при регистрации в прошлых
                  <br /> проектах Happy{' '}
                </div>
                <div className="modal-id__fields">
                  <div>
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.email} />
                  </div>
                  <div>
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.password} />
                    <a href="#" onClick={this.handleForgotPassword}>
                      Забыли пароль?
                    </a>
                  </div>
                </div>
                <div className="btn-field">
                  <div className="btn button5" onClick={this.handleSend}>
                    <span>Войти</span>
                  </div>
                </div>
                <div className="modal-info3 modal-info3--pt">
                  У меня нет <b>HappyID</b>
                  <br />
                  <a href="#" onClick={this.handleSignUp}>
                    Создать
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleForgotPassword = (e) => {
    e.preventDefault()
    this.hide()
    this.props.openForgotPassword()
  }

  handleSignUp = (e) => {
    e.preventDefault()
    this.hide()
    this.props.openSignUp()
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)
    formData.append('password', this.state.password.value)
    formData.append('remember', 'Y')

    await this.props.signInByID(formData)
    this.setState({ pending: false })
    this.hide()
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      [name]: { ...this.state[name], valid }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      [name]: { ...this.state[name], value }
    })
  }
}

export default connect(null, { closeIdModal, signInByID, openSignUp, openForgotPassword })(ModalId)
