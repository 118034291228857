// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level4'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/level4/blast.png',
    '/images/level4/i1.png',
    '/images/level4/i2.png',
    '/images/level4/i3.png',
    '/images/level4/i4.png',
    '/images/level4/i5.png',
    '/images/level4/i6.png',
    '/images/level4/i7.png',
    '/images/level4/i8.png',
    '/images/level4/i9.png',
    '/images/level4/i10.png',
    '/images/level4/eyes.png',

    '/images/level4/circle.png',
    '/images/level4/light1.png',
    '/images/level4/light2.png',
    '/images/level4/agent.png',
    '/images/level4/bg.jpg',
    '/images/level4/bg2.jpg',
    '/images/level4/bubble.png',
    '/images/level4/bubble2.png',

    '/images/level4/blood.png',
    '/images/level4/flea.png',
    '/images/level4/bubble3.png',
    '/images/level4/light3.png',
    '/images/level4/agent2.png',
    '/images/level4/bg3.png',
    '/images/level4/pattern.png',

    '/images/level4/bg4.png',
    '/images/level4/agent3.png',
    '/images/level4/pattern2.png',
    '/images/level4/face1.png',
    '/images/level4/face2.png',
    '/images/level4/face3.png',
    '/images/level4/bubble4.png',
    '/images/level4/bubble5.png',
    '/images/level4/capsule.png',

    '/images/level4/face4.png',
    '/images/level4/capsule2.png',
    '/images/level4/agent4.png',
    '/images/level4/line1.png',
    '/images/level4/line2.png',
    '/images/level4/line3.png',
    '/images/level4/bubble6.png',
    '/images/level4/bubble7.png',
    '/images/level4/bubble8.png',
    '/images/level4/pattern3.png',

    '/images/level4/signal-lines.png',
    '/images/level4/signal.png',
    '/images/level4/bubble9.png',
    '/images/level4/guys.png'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
export const openSelector = (state) => state[moduleName].modal.open
export const successSelector = (state) => state[moduleName].modal.success
export const errorSelector = (state) => state[moduleName].modal.error
export const messageSelector = (state) => state[moduleName].modal.message
export const getNextSelector = (state) => state[moduleName].modal.getNext
/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
