import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { linksSelector } from '../../redux/modules/access'
import { closeSocialModal, openIdModal } from '../../redux/modules/modal'

class Social extends Component {
  static propTypes = {
    links: PropTypes.array.isRequired
  }

  render() {
    if (!this.props.links.length) return null
    return (
      <div className="social">
        {!this.props.noid && <div className="social-icon id-icon" onClick={this.handleIdLogin} />}
        {!this.props.noicons && this.icons}
      </div>
    )
  }

  get icons() {
    return this.props.links.map((el, i) => <div key={i} onClick={() => this.handleClick(el.onclick)} className={`social-icon ${el.auth_service_id}-icon`} />)
  }

  handleIdLogin = (e) => {
    e.preventDefault()
    this.props.closeSocialModal()
    this.props.openIdModal()
  }

  handleClick = (url) => (window.GLOBAL_SOCIAL_WINDOW = window.open(url, '', 'status=no,scrollbars=yes,resizable=yes,width=800,height=800,top=50,left=50'))
}

export default connect(
  (state) => ({
    links: linksSelector(state)
  }),
  { closeSocialModal, openIdModal }
)(Social)
