import axios from 'axios'
import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level1'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/level1/block1.png',
    '/images/level1/block2.png',
    '/images/level1/block3.png',
    '/images/level1/path.png',
    '/images/level1/emitter1.png',
    '/images/level1/agent.png',
    '/images/level1/modal.png',
    '/images/level1/modal-text.png',
    '/images/level1/emitter2.png',
    '/images/level1/level1_step2_agent.png',
    '/images/level1/line.png',
    '/images/level1/line2.png',
    '/images/level1/emitter3.png',
    '/images/level1/step3-bg.png',
    '/images/level1/step3-bg.jpg',
    '/images/level1/step3-block.png',
    '/images/level1/bubble_text.png',
    '/images/level1/emitter3.png',
    '/images/level1/step3-block.png',
    '/images/level1/step3_agent.png',
    '/images/level1/top.png',
    '/images/modal-border.png',
    '/images/modal-agent.png',
    '/images/modal1.jpg',
    '/images/modal2.jpg'
  ],
  loading: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading

/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}

export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
