import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { linksSelector, getLinks } from '../../redux/modules/access'

class Loader extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingSuccess: PropTypes.func.isRequired
  }

  state = {
    loadedImages: 0
  }

  componentDidMount() {
    if (!this.props.links.length) this.props.getLinks()

    this.props.images.map((picture) => {
      const img = new Image()
      img.src = picture
      img.onload = () => this.setState({ loadedImages: this.state.loadedImages + 1 })
      img.onerror = () => {
        throw picture + ' not loaded'
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadedImages } = this.state
    const { images, loadingSuccess, links } = this.props

    if (links.length && loadedImages === images.length && (links.length !== prevProps.links.length || loadedImages !== prevState.loadedImages)) {
      // if (loadedImages === images.length && loadedImages !== prevState.loadedImages) {
      loadingSuccess()
    }
  }

  render() {
    if (this.props.loading) return <div className="loader"></div>
    return null
  }
}

export default connect(
  (state) => ({
    links: linksSelector(state)
  }),
  { getLinks }
)(Loader)
