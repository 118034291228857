import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Header from '../layout/header'
import { api } from '../../redux/config'
import axios from 'axios'

const Help = ({ match }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios
      .get(api + 'help-json.php', { params: { hash: match.params.id } })
      .then(({ data }) => data)
      .then((response) => {
        if (response.success) {
          setData({ name: response.last_name, step: window.location.hash.replace('#', '') || response.step })
        }
      })
  }, [])

  return (
    <div className={data ? 'help active' : 'help'} data-id={data && data.step}>

      <div className="help__title">
        <img src="/images/help/title.png" />
        <div>
          Агент
          <span>{data && data.name}</span>
          благодарен вам!
          <br />
          Вы помогли ему справиться
          <br />
          с преградой.
          <br />
        </div>
      </div>

      <div className="help__subtitle">
        Попробуйте
        <br />
        себя в роли Агента Х5!
      </div>

      <Link to="/" className="help__button">
        <div>Попробовать</div>
      </Link>

      <div className="help__home1">
        <img src="/images/help/home1.png" />
      </div>
      <div className="help__home2">
        <img src="/images/help/home2.png" />
      </div>

      {data && data.step == 1 && (
        <>
          <img src="/images/help/1/img1.png" className="help__pers" />
          <img src="/images/help/1/img2.png" className="help__light" />
        </>
      )}

      {data && data.step == 2 && (
        <>
          <img src="/images/help/2/img1.png" className="help__pers" />
          <img src="/images/help/2/img2.png" className="help__light" />
        </>
      )}

      {data && data.step == 3 && (
        <>
          <img src="/images/help/3/img1.png" className="help__pers" />
          <img src="/images/help/2/img2.png" className="help__light" />
        </>
      )}

      {data && data.step == 4 && (
        <>
          <img src="/images/help/4/img1.png" className="help__pers" />
          <img src="/images/help/2/img2.png" className="help__light" />
        </>
      )}

      {data && data.step == 5 && (
        <>
          <img src="/images/help/5/img1.png" className="help__pers" />
          <img src="/images/help/2/img2.png" className="help__light" />
        </>
      )}
    </div>
  )
}

export default withRouter(Help)
