import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'
import CanvasAnimation from '../common/canvas-animation'

import { loadingSelector, imagesSelector, loadingSuccess } from '../../redux/modules/intro'
import { setLevel } from '../../redux/modules/access'

class Intro extends Component {
  state = {
    x: 0
  }

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />

    return (
      <Fragment>
        <Header style={2} />
        <div className="intro page fade-in" onMouseMove={this.handleMouseMove}>
          <div className="content">
            <div className="intro__bg" style={{ transform: `translateX(${this.state.x}%)` }}></div>
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />

              <div className="intro__floor">
                <img src={images[1]} alt="" />
              </div>
              <div className="intro__agent">
                <img src={images[2]} alt="" />
              </div>
              <div className="intro__bubble">
                <img className="intro__bubble__img" src={images[3]} alt="" />
                <img className="intro__bubble__text" src={images[10]} alt="" />
              </div>
              <div className="intro__bubble2">
                <img src={images[4]} alt="" />
              </div>
              <div className="intro__hand">
                <div className="intro__wave">
                  <img src="/images/intro/wave2.png" alt="" />
                </div>
                <img src={images[6]} alt="" />
              </div>
              <div className="intro__bubble3">
                <img src={images[11]} alt="" />
              </div>
            </div>
            <div className="btn-wrap">
              <Link to="/level-1" className="btn button2" onClick={this.handleSetLevel}>
                <span>дальше</span>
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  handleSetLevel = () => this.props.setLevel(1)

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      x: parseInt(((ev.pageX - el.offsetWidth / 2) / el.offsetWidth) * 15)
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state)
  }),
  { loadingSuccess, setLevel }
)(Intro)
