import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'
import CanvasAnimation from '../common/canvas-animation'

import { loadingSelector, imagesSelector, loadingSuccess } from '../../redux/modules/finish'

class Finish extends Component {
  state = {
    step: 1
  }

  id = null

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />
    const { step } = this.state
    return (
      <Fragment>
        <Header style={3} />
        <div className={`finish2 page fade-in step-${step}`}>
          <div className="finish__bg" />
          <div className="content">
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />

              {/*this.state.step >= 7 && (
                <Fragment>
                  <div className="layer finish__h">
                    <img src="/images/finish2/step3/1.png" alt="" />
                  </div>
                  <div className="layer finish__bubble2">
                    <img src="/images/finish2/step3/bubble.png" alt="" />
                  </div>
                </Fragment>
              )*/}
              {this.state.step === 8 && (
                <Fragment>
                  <div className="layer finish__flea">
                    <img src="/images/finish2/step3/flea.png" alt="" />
                  </div>
                  <div className="layer finish__cat">
                    <img src="/images/finish2/step3/cat.png" alt="" />
                  </div>
                  <div className="layer finish__bubble3">
                    <img src="/images/finish2/step3/2.png" alt="" />
                  </div>
                  <Link to="/ratings" className="layer finish__bubble4">
                    <img src="/images/finish2/step3/bubble3.png" alt="" />
                  </Link>
                  <div className="layer finish__bubble5">
                    <img src="/images/finish2/step3/bubble4.png" alt="" />
                  </div>
                  <Link to="/game" className="layer finish__btn">
                    <img src="/images/finish2/step3/btn.png" alt="" />
                  </Link>
                </Fragment>
              )}
              {this.state.step < 4 && (
                <Fragment>
                  <div className="layer finish__fire">
                    <img src="/images/finish2/fire.png" alt="" />
                  </div>
                  <div className="layer finish__1">
                    <img src="/images/finish2/step1/1.png" alt="" />
                  </div>
                  <div className="layer finish__2">
                    <img src="/images/finish2/step1/2.png" alt="" />
                  </div>
                  <div className="layer finish__3">
                    <img src="/images/finish2/step1/3.png" alt="" />
                  </div>
                  <div className="layer finish__4">
                    <img src="/images/finish2/step1/4.png" alt="" />
                  </div>
                </Fragment>
              )}
              {this.state.step >= 4 && this.state.step < 7 && (
                <Fragment>
                  <div className="layer finish__2_0">
                    <img src="/images/finish2/step2/1.png" alt="" />
                  </div>
                  <div className="layer finish__fire2">
                    <img src="/images/finish2/step2/fire.png" alt="" />
                  </div>
                  <div className="layer finish__2_2">
                    <img src="/images/finish2/step2/3.png" alt="" />
                  </div>
                  <div className="layer finish__2_3">
                    <img src="/images/finish2/step2/4.png" alt="" />
                  </div>
                  <div className="layer finish__2_4">
                    <img src="/images/finish2/step2/5.png" alt="" />
                  </div>
                  <div className="layer finish__2_1">
                    <img src="/images/finish2/step2/2.png" alt="" />
                  </div>
                  <div className="layer finish__2_5">
                    <img src="/images/finish2/step2/6.png" alt="" />
                  </div>
                </Fragment>
              )}
              {this.state.step === 6 && (
                <div className="layer finish__bubble">
                  <img src="/images/finish2/step2/bubble.png" alt="" />
                </div>
              )}
              {this.state.step === 6 && (
                <div className="layer finish__guys">
                  <img src="/images/finish2/step2/guys.png" alt="" />
                </div>
              )}
              <div className="layer finish__floor">
                <img src="/images/finish2/step1/floor.png" alt="" />
              </div>
              {this.state.step < 4 && (
                <Fragment>
                  <div className="layer finish__man finish__man1">
                    <CanvasAnimation delay={1800} src="/images/finish2/step1/man.png" once={true} size={[94, 144]} fw={94} steps={1} speed={2} />
                  </div>
                  <div className="layer finish__bird">
                    <CanvasAnimation delay={1800} src="/images/finish2/step1/bird.png" once={true} size={[633, 551]} fw={633} steps={1} speed={2} />
                  </div>
                  <div className="layer finish__bird2">
                    <img src="/images/finish2/step1/bird2.png" alt="" />
                  </div>
                  <div className="layer finish__bird3">
                    <img src="/images/finish2/step1/bird3.png" alt="" />
                  </div>
                </Fragment>
              )}
              {/*this.state.step === 3 && (
                <Fragment>
                  <div className="layer finish2__text">
                    <img src="/images/finish2/step1/text.png" alt="" />
                  </div>
                </Fragment>
              )*/}
              {this.state.step >= 3 && this.state.step < 7 && (
                <Fragment>
                  <div className="layer finish__bird4">
                    <img src="/images/finish2/step1/bird2.png" alt="" />
                  </div>
                  <div className="layer finish__bird5">
                    <img src="/images/finish2/step1/bird3.png" alt="" />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  componentDidMount(prevProps, prevState) {
    if (this.state.step === 1 && !this.props.loading) {
      this.id = setTimeout(() => {
        this.setState({ step: 2 })
      }, 1000)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 1 && !this.props.loading && this.props.loading !== prevProps.loading) {
      this.id = setTimeout(() => {
        this.setState({ step: 2 })
      }, 1000)
    }
    if (this.state.step === 2 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 3 })
      }, 2500)
    }
    if (this.state.step === 3 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 4 })
      }, 2500)
    }
    if (this.state.step === 4 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 5 })
      }, 1000)
    }
    if (this.state.step === 5 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 6 })
      }, 2500)
    }
    if (this.state.step === 6 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 8 })
      }, 4000)
    }
    if (this.state.step === 7 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 8 })
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state)
  }),
  { loadingSuccess }
)(Finish)
