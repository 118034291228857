import React, { useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import Header from '../layout/header'
import { getRatings, goback } from '../../redux/modules/ratings'

const Ratings = ({ data, getRatings, mobile, access, goback }) => {
  const scrBar = useRef()

  useEffect(() => {
    if (!data) {
      getRatings()
    }

    setTimeout(() => {
      if (access.user) {
        const element = document.querySelector('.ratings__table tr[data-id="' + access.user.id + '"]')
        if (element) {
          if (mobile) {
            window.scrollTo(0, element.getBoundingClientRect().top)
          } else {
            scrBar.current.scrollTop(element.offsetTop)
          }
        }
      }
    }, 100)
  }, [data, access])

  if (!data) return null

  const create = () => {
    var array = []

    data.results.forEach((e, i) => {
      const index = i + 1
      array.push(
        <tr key={`item${i}`} data-id={e.user_id}>
          <td>{index}</td>
          <td>
            <div className="rating__table-image-wrap">
              {e.image ? <div className="rating__table-image" style={{ backgroundImage: `url(${e.image})` }} /> : <div className="rating__table-image" />}
              {e.logo && <div className="rating__table-logo" data-id={e.logo} />}
            </div>
          </td>
          <td dangerouslySetInnerHTML={{ __html: e.agent }} />
          <td>{e.strong_count}</td>
          <td>{e.clever_count}</td>
          <td>{e.brave_count}</td>
          <td>{e.insight_count}</td>
          <td>{e.quick_count}</td>
          <td>{e.score}</td>
        </tr>
      )
    })

    return (
      <table className="ratings__table">
        <thead>
          <tr>
            <th>Место</th>
            <th />
            <th>агент</th>
            <th>
              <div>
                <span>
                  Самые
                  <br />
                  сильные
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  Самые
                  <br />
                  умные
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  Самые
                  <br />
                  храбрые
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  Самые
                  <br />
                  проницательные
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  Самые
                  <br />
                  находчивые
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Очки</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>{array}</tbody>
      </table>
    )
  }

  return (
    <Fragment>
      <Header style={2} />
      <div className="ratings fade-in">
        <div className="ratings__title" />
        {/*<div className="ratings__title2" />
        <div className="btn-wrap">
          <Link to="/" className="btn button8">
            <span>На главную</span>
          </Link>
        </div>*/}
        <div className="ratings__subtitle">Команда агента</div>

        <div className="ratings__table-wrap">
          <div onClick={goback} className="modal-close" />
          <Scrollbars ref={scrBar} autoHeight autoHeightMin={0} autoHeightMax={!mobile ? 380 : 10000}>
            {create(data)}
          </Scrollbars>
        </div>
      </div>
    </Fragment>
  )
}

export default connect(
  (state) => ({
    data: state.ratings,
    mobile: state.mobile,
    access: state.access
  }),
  { getRatings, goback }
)(Ratings)
