import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import Counter from '../common/counter2'
import { closeForgotPassword, sendForgotPassword, notSuceessForgotPassword, sendChangePassword } from '../../redux/modules/modal'

class ModalAlmostComplite extends Component {
  state = {
    triggerValidate: false,
    triggerValidate2: false,
    visible: false,
    email: {
      value: '',
      name: 'email',
      type: 'name',
      placeholder: 'Логин (E-mail)',
      validate: 'email',
      valid: false
    },
    code: {
      value: '',
      name: 'code',
      type: 'text',
      placeholder: 'Код',
      validate: 'text',
      valid: false
    },
    password: {
      value: '',
      name: 'password',
      type: 'password',
      placeholder: 'Пароль',
      validate: 'password',
      valid: false
    },
    confirmPassword: {
      value: '',
      name: 'confirmPassword',
      type: 'password',
      placeholder: 'Подтверждение пароля',
      validate: 'password',
      valid: false,
      compare: 'password'
    }
  }

  id = null

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeForgotPassword, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return (
      <div className={`modal modal-message forgot-password ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="m3">
                <img src="/images/m3.png" alt="" />
              </div>
              <div className="modal-close" onClick={this.hide}></div>
              <div className="modal-content">
                {this.props.success ? (
                  <div class="modal-title5">
                    Для воссстановления пароля <br />
                    введите код, отправленный вам <br />
                    на электронную почту, <br />и новый пароль
                  </div>
                ) : this.props.success2 ? (
                  <div>
                    <div class="modal-title2">Ваш пароль</div>
                    <div class="modal-title3">успешно изменен.</div>
                  </div>
                ) : (
                  <div className="forgot-password__title">
                    <img src="images/modal/title6.png" alt="" />
                  </div>
                )}
                <div className="fp-error-message">{this.props.error}</div>
                {this.props.success ? (
                  <Fragment>
                    <div className=""></div>
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.code} />
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.password} />
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.confirmPassword} />
                    <div className="form-counter">
                      Код действителен в течении <Counter callback={this.props.notSuceessForgotPassword} /> сек.
                    </div>
                    <div className="forgot-password__btn">
                      <div className="btn btn11" onClick={this.handleSend2}>
                        <span>изменить пароль</span>
                      </div>
                    </div>
                  </Fragment>
                ) : this.props.success2 ? (
                  <div className="forgot-password__btn">
                    <div className="btn btn11" onClick={this.hide}>
                      <span>Закрыть</span>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.email} />
                    <div className="forgot-password__btn">
                      <div className="btn btn11" onClick={this.handleSend}>
                        <span>восстановить пароль</span>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.state.email.valid || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)

    await this.props.sendForgotPassword(formData)
    this.setState({ pending: false })
  }

  handleSend2 = async () => {
    this.setState({ triggerValidate2: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)
    formData.append('code', this.state.code.value)
    formData.append('password', this.state.password.value)
    formData.append('confirmPassword', this.state.confirmPassword.value)
    await this.props.sendChangePassword(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      [name]: { ...this.state[name], valid }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      [name]: { ...this.state[name], value }
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    error: state.modal.forgot_password.error,
    success: state.modal.forgot_password.success,
    success2: state.modal.forgot_password.success2
  }),
  { closeForgotPassword, sendForgotPassword, notSuceessForgotPassword, sendChangePassword }
)(ModalAlmostComplite)
