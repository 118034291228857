import React, { Component, Fragment } from 'react'

class Icons extends Component {
  state = {
    icons: [],
    index: 21
  }

  id = null
  id2 = null

  componentDidMount() {
    this.id2 = setTimeout(() => {
      this.create(1)
      this.create(2)
      this.create(3)
      this.create(4)
      this.create(5)
      this.create(6)
      this.create(7)
      this.create(8)
      this.create(9)
      this.create(10)
      this.create(11)
      this.create(12)

      this.id = setInterval(() => {
        this.create(1)
        this.create(2)
        this.create(3)
        this.create(4)
        this.create(5)
        this.create(6)
        this.create(7)
        this.create(8)
        this.create(9)
        this.create(10)
        this.create(11)
        this.create(12)
      }, 2500)
    }, 800)
  }

  componentWillUnmount() {
    clearInterval(this.id)
    clearTimeout(this.id2)
  }

  render() {
    return <Fragment>{this.state.icons}</Fragment>
  }

  create = (num) => {
    this.setState({
      icons: [
        ...this.state.icons,
        <div key={this.state.index} className={`l3-icon l3-anim-${num}`}>
          <img src={`/images/level3/i${num}.svg`} />
        </div>
      ],
      index: this.state.index + 1
    })
  }
}

export default Icons
