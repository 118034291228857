import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'
import { getPlaceData } from '../../redux/modules/access'
import { closeWhereDoYouWork, sendPhoto, sendWhereDoYouWork } from '../../redux/modules/modal'

class ModalHappyId extends Component {
  state = {
    triggerValidate: false,
    UF_NET: {
      value: '',
      name: 'UF_NET',
      placeholder: 'Принадлежность к сетям',
      valid: false
    },
    WORK_POSITION: {
      value: '',
      name: 'WORK_POSITION',
      type: 'text',
      placeholder: 'Должность',
      validate: 'text',
      valid: false
    },
    UF_REGION: {
      value: '',
      name: 'UF_REGION',
      placeholder: 'Регион',
      valid: false
    },
    WORK_CITY: {
      value: '',
      name: 'WORK_CITY',
      type: 'text',
      placeholder: 'Город',
      validate: 'text',
      valid: false
    },
    UF_WORK_PLACE: {
      value: '',
      name: 'UF_WORK_PLACE',
      type: 'text',
      placeholder: 'Магазин/офис',
      validate: 'text',
      valid: false
    },
    visible: false,
    pending: false
  }

  id = null

  componentDidMount() {
    if (!this.props.ufNetOptions.length) this.props.getPlaceData()
    this.id = setTimeout(() => this.setState({ visible: true }), 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const { success } = this.props
    if (success && prevProps.success !== success) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    this.id = setTimeout(this.props.closeWhereDoYouWork, 600)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    const { category } = this.props
    const url = this.state.PERSONAL_PHOTO ? window.URL.createObjectURL(this.state.PERSONAL_PHOTO) : ''

    return (
      <div className={`modal modal-message yourwork ${this.state.visible ? 'visible' : ''}`}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="yourwork__title">
                <img src="/images/modal/title3.png" alt="" />
              </div>

              <div className="yourwork__form">
                <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.UF_NET} options={this.ufNetOptions} />
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.WORK_POSITION} />
                <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.UF_REGION} options={this.ufRegionOptions} />
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.WORK_CITY} />
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.UF_WORK_PLACE} />
              </div>
              <div className="btn button5" onClick={this.handleSend}>
                <span>зарегистрироваться</span>
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get ufNetOptions() {
    const options = this.props.ufNetOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.UF_NET.placeholder}
      </option>,
      ...options
    ]
  }

  get ufRegionOptions() {
    const options = this.props.ufRegionOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.UF_REGION.placeholder}
      </option>,
      ...options
    ]
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('UF_EMPLOYEE', 1)
    formData.append('UF_SOCSERV_FINISHED', 1)

    formData.append('WORK_POSITION', this.state.WORK_POSITION.value)
    formData.append('UF_REGION', this.state.UF_REGION.value)
    formData.append('WORK_CITY', this.state.WORK_CITY.value)
    formData.append('UF_WORK_PLACE', this.state.UF_WORK_PLACE.value)
    await this.props.sendWhereDoYouWork(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({ [name]: { ...this.state[name], value } })
  }
}

export default connect(
  (state) => ({
    success: state.modal.where_do_you_work.success,
    ufNetOptions: state.access.place_data.net,
    ufRegionOptions: state.access.place_data.region
  }),
  { closeWhereDoYouWork, sendPhoto, sendWhereDoYouWork, getPlaceData }
)(ModalHappyId)
