/**
 * Constants
 * */
export const moduleName = 'mobile'
export const MOBILE = `${moduleName}/MOBILE`

/**
 * Reducer
 * */
export default (mobile = false, action) => {
  return action.type === MOBILE ? action.mobile : mobile
}

/**
 * Action Creators
 * */
export const setMobile = (mobile) => {
  return {
    type: MOBILE,
    mobile
  }
}
