import React, { useEffect } from 'react'
import Header from '../layout/header'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import 'jquery.transit'

const Game = () => {
  useEffect(() => {
    if (window.timer1) {
      clearInterval(window.timer1)
    }
    if (window.timer2) {
      clearInterval(window.timer2)
    }

    const c = window.innerWidth <= 990 ? 40 : 100

    const view = $('#gameview')
    const max = view.width() - c
    const min = c
    const delay = 1000
    const cursor = $('.game__cursor')

    const create = () => {
      if (window.document.hasFocus()) {
        const rand = parseInt(Math.random() * (max - min) + min)
        const randEnemy = Math.round(Math.random() * (2 - 1) + 1)
        const type = Math.round(Math.random() * (3 - 1) + 1)
        const animate = Math.round(Math.random() * (5 - 2) + 2)

        view.append('<div data-enemy="' + randEnemy + '" data-type="' + type + '" data-animate="' + animate + '" class="game__enemy' + randEnemy + ' game__enemy" style="left:' + rand + 'px;"></div>')

        $('.game__enemy')
          .unbind('click')
          .bind('click', function() {
            const top = $(this).offset().top - view.offset().top
            const left = $(this).offset().left - view.offset().left
            const enemy = $(this).attr('data-enemy')
            view.append('<div data-enemy="' + enemy + '" style="left: ' + left + 'px; top: ' + top + 'px" class="game__boom"></div>')
            if (enemy === '1') {
              const val1 = parseInt($('#game__score1').text()) + 1
              $('#game__score1').text(val1)
            } else {
              const val2 = parseInt($('#game__score2').text()) + 1
              $('#game__score2').text(val2)
              $('.game__bubble1').addClass('active')

              setTimeout(() => {
                $('.game__bubble1').removeClass('active')
              }, 350)
            }

            $(this).remove()
          })
      }
    }

    const destroy = () => {
      if (window.document.hasFocus()) {
        if ($('.game__enemy').length) {
          $('.game__enemy').each(function() {
            if ($(this).offset().top - $(this).height() > view.height()) {
              $(this).remove()
              const val = parseInt($('.game__health').attr('data-count')) - 1

              if (val <= 0) {
                clearInterval(window.timer1)
                clearInterval(window.timer2)

                $('.game__view').addClass('end')
                $('.game__boom, .game__enemy').remove()
                $('.game__bubble2').addClass('active')
              }

              $('.game__health').attr('data-count', val)
            }
          })
        }
      }
    }

    window.timer1 = setInterval(create, delay)
    window.timer2 = setInterval(destroy, 50)

    view.unbind('mousemove').bind('mousemove', function(e) {
      const x = e.pageX - $(this).offset().left
      const y = e.pageY - $(this).offset().top
      cursor.css({ x, y })
    })

    $('.game__over-button')
      .unbind('click')
      .bind('click', function() {
        $('#game__score1, #game__score2').text(0)
        $('.game__health').attr('data-count', 3)
        $('.game__view').removeClass('end')
        $('.game__bubble2').removeClass('active')

        window.timer1 = setInterval(create, delay)
        window.timer2 = setInterval(destroy, 50)
      })
  }, [])

  return (
    <>
      <Header style={1} />
      <div className="game">
        <div className="game__megatron" />
        <div className="game__bubble game__bubble1" />
        <div className="game__bubble game__bubble2" />

        <div className="game__in">
          <div className="game__view">
            <div className="game__score game__score1">
              <div id="game__score1">0</div>
              <span>киберблохи</span>
            </div>
            <div className="game__score game__score2">
              <div id="game__score2">0</div>
              <span>мегатроны</span>
            </div>
            <div className="game__view-in" id="gameview">
              <div className="game__cursor" />
            </div>

            <div className="game__over">
              <div className="game__over-title">
                <div>Game</div>
                over
              </div>
              <div className="game__over-button">
                <span>Еще попыточка!</span>
              </div>
            </div>
          </div>
          <div className="game__health" data-count="3">
            <svg width="162pt" height="158pt" viewBox="0 0 162 158" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  fill="#000000"
                  opacity="1.00"
                  d=" M 118.52 0.00 L 118.60 0.00 C 129.10 3.35 139.65 6.54 150.15 9.91 C 154.14 26.19 157.85 42.55 162.00 58.79 L 162.00 59.25 C 135.67 92.17 109.31 125.06 82.99 158.00 L 82.97 158.00 C 55.33 125.72 27.62 93.51 0.02 61.20 C 2.54 49.13 5.09 37.05 7.63 24.98 C 7.64 23.33 9.47 23.09 10.61 22.51 C 23.61 17.08 36.47 11.30 49.51 5.98 C 59.94 17.20 70.48 28.33 81.00 39.47 C 93.54 26.35 106.02 13.16 118.52 0.00 Z"
                />
              </g>
            </svg>
            <svg width="162pt" height="158pt" viewBox="0 0 162 158" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  fill="#000000"
                  opacity="1.00"
                  d=" M 118.52 0.00 L 118.60 0.00 C 129.10 3.35 139.65 6.54 150.15 9.91 C 154.14 26.19 157.85 42.55 162.00 58.79 L 162.00 59.25 C 135.67 92.17 109.31 125.06 82.99 158.00 L 82.97 158.00 C 55.33 125.72 27.62 93.51 0.02 61.20 C 2.54 49.13 5.09 37.05 7.63 24.98 C 7.64 23.33 9.47 23.09 10.61 22.51 C 23.61 17.08 36.47 11.30 49.51 5.98 C 59.94 17.20 70.48 28.33 81.00 39.47 C 93.54 26.35 106.02 13.16 118.52 0.00 Z"
                />
              </g>
            </svg>
            <svg width="162pt" height="158pt" viewBox="0 0 162 158" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  fill="#000000"
                  opacity="1.00"
                  d=" M 118.52 0.00 L 118.60 0.00 C 129.10 3.35 139.65 6.54 150.15 9.91 C 154.14 26.19 157.85 42.55 162.00 58.79 L 162.00 59.25 C 135.67 92.17 109.31 125.06 82.99 158.00 L 82.97 158.00 C 55.33 125.72 27.62 93.51 0.02 61.20 C 2.54 49.13 5.09 37.05 7.63 24.98 C 7.64 23.33 9.47 23.09 10.61 22.51 C 23.61 17.08 36.47 11.30 49.51 5.98 C 59.94 17.20 70.48 28.33 81.00 39.47 C 93.54 26.35 106.02 13.16 118.52 0.00 Z"
                />
              </g>
            </svg>
          </div>

          <div className="game__buttons">
            <Link to="/" className="game__button1">
              <span>
                Еще раз выполнить
                <br />
                миссию Агента
              </span>
            </Link>
            <Link to="/ratings" className="game__button2">
              <span>
                Посмотреть
                <br />
                Рейтинг Агентов
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Game
