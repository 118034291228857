import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Header from '../layout/header'
import CanvasAnimation from '../common/canvas-animation'

import { loadingSelector, imagesSelector, loadingSuccess, getPage } from '../../redux/modules/level5'

import { openModal, closeModal, messageSelector, errorSelector, successSelector, openSelector, sendForm, closeSuccess, closeError } from '../../redux/modules/modal'
import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'
import { setLevel } from '../../redux/modules/access'

class Level5 extends Component {
  state = {
    step: 1
  }

  id = null

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />
    const { step } = this.state

    return (
      <Fragment>
        <Header style={step === 7 ? 1 : 2} />
        <div className={`level5 page fade-in step-${step}`}>
          {step === 4 && <div className="layer level5__bg3" />}
          {step === 5 && <div className="layer level5__bg4" />}
          {step === 6 && <div className="layer level5__bg5" />}
          {step === 7 && <div className="layer level5__bg6" />}
          <div className="content">
            <div className="spacer">
              <img src="/images/spacer.png" alt="" />
              {(step === 2 || step === 3) && (
                <div className="layer level5__bg2">
                  <img src="/images/level5/bg.png" alt="" />
                </div>
              )}
              {step < 3 && (
                <Fragment>
                  <div className="layer level5__bg">
                    <img src="/images/level5/bg.jpg" alt="" />
                  </div>
                  <div className="layer level5__bubble">
                    <img src="/images/level5/bubble.png" alt="" />
                  </div>
                  <div className="layer level5__megatron">
                    <div>
                      <img className="level5__megatron__shadow" src="/images/level5/megatron-shadow.png" alt="" />
                      <img className="level5__megatron__img" src="/images/level5/megatron.png" alt="" />
                      <div className="layer level5__megatron__eye">
                        <div className="layer level5__megatron__eye-in" />
                      </div>
                      <div className="layer level5__megatron__eye2">
                        <div className="layer level5__megatron__eye-in" />
                      </div>
                      <div className="layer level5__megatron__eyebrow" />
                      <div className="layer level5__megatron__eyebrow2" />
                    </div>
                  </div>
                  <div className="layer level5__bubble">
                    <img src="/images/level5/bubble-arrow.png" alt="" />
                  </div>
                  <div className="layer level5__guys">
                    <img src="/images/level5/guys.png" alt="" />
                  </div>

                  <div className="layer level5__bubble2-1">
                    <img src="/images/level5/bubble2-1.png" alt="" />
                  </div>
                  <div className="layer level5__bubble2">
                    <img src="/images/level5/bubble2.png" alt="" />
                    <div className="level5__btn" onClick={this.props.openModal}>
                      <div className="btn button6">
                        <span>позвать храброго</span>
                      </div>
                    </div>
                  </div>

                  <div className="level5__btn mobile" onClick={this.props.openModal}>
                    <div className="btn button6">
                      <span>позвать храброго</span>
                    </div>
                  </div>
                </Fragment>
              )}
              {(step === 2 || step === 3) && (
                <Fragment>
                  <div className="layer level5__agent">
                    <img src="/images/level5/agent.png" alt="" />
                  </div>
                  <div className="layer level5__bubble3">
                    <img src="/images/level5/bubble3.png" alt="" />
                  </div>
                  <div className="layer level5__fire">
                    <img src="/images/level5/fire.png" alt="" />
                  </div>
                  <div className="layer level5__fire level5__fire-2">
                    <img src="/images/level5/fire.png" alt="" />
                  </div>
                  <div className="layer level5__fire2">
                    <img src="/images/level5/fire2.png" alt="" />
                  </div>
                  <div className="layer level5__fire2 level5__fire2-2">
                    <img src="/images/level5/fire2.png" alt="" />
                  </div>
                  <div className="layer level5__fire2 level5__fire2-3">
                    <img src="/images/level5/fire2.png" alt="" />
                  </div>
                  <div className="layer level5__fire3">
                    <img src="/images/level5/fire3.png" alt="" />
                  </div>
                  <div className="layer level5__fire3 level5__fire3-2">
                    <img src="/images/level5/fire3.png" alt="" />
                  </div>
                  <div className="layer level5__fire3 level5__fire3-3">
                    <img src="/images/level5/fire3.png" alt="" />
                  </div>
                </Fragment>
              )}
              {(step === 3 || step === 4) && (
                <Fragment>
                  <div className="layer level5__megatron2">
                    <img src="/images/level5/megatron2.png" alt="" />
                  </div>
                  <div className="layer level5__smoke">
                    <img src="/images/level5/smoke.png" alt="" />
                  </div>
                  <div className="layer level5__pc">
                    <img src="/images/level5/pc.png" alt="" />
                  </div>
                  <div className="layer level5__bubble4">
                    <img src="/images/level5/bubble4.png" alt="" />
                  </div>
                  <div className="layer level5__guys2">
                    <img src="/images/level5/guys2.png" alt="" />
                  </div>
                </Fragment>
              )}
              {(step === 4 || step === 5) && (
                <Fragment>
                  <div className="layer level5__screen">
                    <img src="/images/level5/screen.png" alt="" />
                    <img className="screen_num screen_num1" src="/images/level5/screen1.png" alt="" />
                    <img className="screen_num screen_num2" src="/images/level5/screen2.png" alt="" />
                    <img className="screen_num screen_num3" src="/images/level5/screen3.png" alt="" />
                    <img className="screen_num screen_num4" src="/images/level5/screen4.png" alt="" />
                    <img className="screen_num screen_num5" src="/images/level5/screen5.png" alt="" />
                  </div>
                  <div className="layer level5__nums">
                    <img src="/images/level5/nums.png" alt="" />
                  </div>
                  <div className="layer level5__hand">
                    <img src="/images/level5/hand.png" alt="" />
                    <div className="layer level5__hand__spl">
                      <img src="/images/level5/spl.png" alt="" />
                    </div>
                  </div>
                  <div className="layer level5__bubble5">
                    <img src="/images/level5/bubble5.png" alt="" />
                    <div className="level5__btn2" onClick={this.nextStep}>
                      <div className="btn button7">
                        <span>ввести код</span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {(step === 5 || step === 6) && (
                <Fragment>
                  <div className="layer level5__x8">
                    <img src="/images/level5/x8.png" alt="" />
                  </div>
                  <div className="layer level5__x8-thinks">
                    <img src="/images/level5/x8-thinks.png" alt="" />
                  </div>
                  <div className="layer level5__bubble6">
                    <img src="/images/level5/bubble6.png" alt="" />
                  </div>
                  <div className="level5__btn3" onClick={this.nextStep}>
                    <div className="btn button8">
                      <span>стоп</span>
                    </div>
                  </div>
                </Fragment>
              )}
              {(step === 6 || step === 7) && (
                <Fragment>
                  <div className="layer level5__pc3">
                    <img src="/images/level5/pc3.png" alt="" />
                  </div>
                  <div className="layer level5__agent2">
                    <img src="/images/level5/agent2.png" alt="" />
                  </div>
                  <div className="layer level5__bubble7">
                    <img src="/images/level5/bubble7.png" alt="" />
                    <div className="level5__btn4" onClick={this.nextStep}>
                      <div className="btn button2">
                        <span>что же будет?</span>
                      </div>
                    </div>
                  </div>
                  <div className="layer level5__bubble8">
                    <img src="/images/level5/bubble8.png" alt="" />
                  </div>
                </Fragment>
              )}
              {(step === 7 || step === 8) && (
                <Fragment>
                  <div className="layer level5__pc2">
                    <img src="/images/level5/pc2.png" alt="" />
                  </div>
                  <div className="layer level5__earth">
                    <img src="/images/level5/earth.png" alt="" />
                  </div>
                  <div className="layer level5__fire4">
                    <CanvasAnimation delay={2000} src="/images/level5/fire4.png" size={[380, 201]} fw={380} steps={7} speed={2} />
                  </div>
                  <div className="layer level5__bubble9">
                    <img src="/images/level5/bubble9.png" alt="" />
                  </div>
                </Fragment>
              )}
              {step === 3 && (
                <div className="btn-wrap">
                  <div className="btn button9" onClick={this.nextStep}>
                    <span>дальше</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {this.props.open && <Modal getNext={this.nextStep} send={this.props.sendForm} close={this.props.closeModal} success={this.props.success} error={this.props.error} category="3" />}
        {this.props.error && <ErrorMessage close={this.props.closeError} message={this.props.message} />}
        {this.props.success && <SuccessMessage nextStep={this.nextStep} close={this.props.closeSuccess} />}
      </Fragment>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 2 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.setState({ step: 3 })
      }, 6000)
    }
    // if (this.state.step === 3 && this.state.step !== prevState.step) {
    //   this.id = setTimeout(() => {
    //     this.setState({ step: 4 })
    //   }, 3000)
    // }
    if (this.state.step === 7 && this.state.step !== prevState.step) {
      this.id = setTimeout(() => {
        this.props.setLevel(6)
        this.props.getPage('/finish')
      }, 5500)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }
}

export default connect(
  (state) => ({
    loading: loadingSelector(state),
    images: imagesSelector(state),

    message: messageSelector(state),
    success: successSelector(state),
    error: errorSelector(state),
    open: openSelector(state)
  }),
  { loadingSuccess, closeModal, openModal, closeModal, sendForm, closeSuccess, closeError, getPage, setLevel }
)(Level5)
