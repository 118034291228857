import axios from 'axios'
import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

import { getProfile, USER_AUTHORIZED, SOCIAL_AUTH_1, SOCIAL_AUTH_2, SOCIAL_AUTH_ERROR, logOut } from './access'

/**
 * Constants
 * */
export const moduleName = 'modal'

export const OPEN = `${moduleName}/OPEN`
export const CLOSE = `${moduleName}/CLOSE`
export const ERROR = `${moduleName}/ERROR`
export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`
export const SUCCESS = `${moduleName}/SUCCESS`
export const CLOSE_SUCCESS = `${moduleName}/CLOSE_SUCCESS`

export const OPEN_SOCIAL = `${moduleName}/OPEN_SOCIAL`
export const CLOSE_SOCIAL = `${moduleName}/CLOSE_SOCIAL`

export const OPEN_ID = `${moduleName}/OPEN_ID`
export const CLOSE_ID = `${moduleName}/CLOSE_ID`
export const SUCCESS_ID = `${moduleName}/SUCCESS_ID`
export const CLOSE_SUCCESS_ID = `${moduleName}/CLOSE_SUCCESS_ID`

export const CLOSE_ALMOST_COMPLETE = `${moduleName}/CLOSE_ALMOST_COMPLETE`
export const CLOSE_SOCIAL_AUTH_ERROR = `${moduleName}/CLOSE_SOCIAL_AUTH_ERROR`

export const OPEN_SIGN_UP = `${moduleName}/OPEN_SIGN_UP`
export const CLOSE_SIGN_UP = `${moduleName}/CLOSE_SIGN_UP`
export const SUCCESS_SIGN_UP = `${moduleName}/SUCCESS_SIGN_UP`
export const CLOSE_SIGN_UP_SUCCESS = `${moduleName}/CLOSE_SIGN_UP_SUCCESS`

export const SET_SIGN_UP_TYPE = `${moduleName}/SET_SIGN_UP_TYPE`

export const OPEN_WHERE_DO_YOU_WORK = `${moduleName}/OPEN_WHERE_DO_YOU_WORK`
export const CLOSE_WHERE_DO_YOU_WORK = `${moduleName}/CLOSE_WHERE_DO_YOU_WORK`
export const CLOSE_ERROR_WHERE_DO_YOU_WORK = `${moduleName}/CLOSE_ERROR_WHERE_DO_YOU_WORK`
export const SUCCESS_WHERE_DO_YOU_WORK = `${moduleName}/SUCCESS_WHERE_DO_YOU_WORK`

export const ERROR_MESSAGE = `${moduleName}/ERROR_MESSAGE`
export const CLOSE_ERROR_MESSAGE = `${moduleName}/CLOSE_ERROR_MESSAGE`
export const CLOSE_SOCIAL_SUCCESS = `${moduleName}/CLOSE_SOCIAL_SUCCESS`
export const OPEN_SOCIAL_SUCCESS = `${moduleName}/OPEN_SOCIAL_SUCCESS`

export const OPEN_FORGOT_PASSWORD = `${moduleName}/OPEN_FORGOT_PASSWORD`
export const CLOSE_FORGOT_PASSWORD = `${moduleName}/CLOSE_FORGOT_PASSWORD`
export const ERROR_FORGOT_PASSWORD = `${moduleName}/ERROR_FORGOT_PASSWORD`
export const SUCCESS_FORGOT_PASSWORD = `${moduleName}/SUCCESS_FORGOT_PASSWORD`
export const SUCCESS_CHANGE_PASSWORD = `${moduleName}/SUCCESS_CHANGE_PASSWORD`

/**
 * Reducer
 * */
const defaultState = {
  help: {
    open: false,
    error: false,
    success: false,
    message: '',
    getNext: false
  },
  error_message: {
    open: false,
    message: false
  },
  social: {
    open: false
  },
  id: {
    open: false
  },
  almost_complete: {
    open: false
  },
  sign_up: {
    open: false,
    success: false
  },
  where_do_you_work: {
    open: false,
    success: false
  },
  social_success: {
    open: false
  },
  forgot_password: {
    open: false,
    error: '',
    success: false,
    success2: false
  }
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case ERROR_MESSAGE:
      return { ...state, error_message: { ...state.error_message, open: true, message: payload.message } }

    case CLOSE_ERROR_MESSAGE:
      return { ...state, error_message: { ...state.error_message, open: false, message: '' } }

    // позвоть на помощь по email
    case OPEN:
      return { ...state, help: { ...state.help, open: true } }

    case CLOSE:
      return { ...state, help: { ...state.help, open: false } }

    case SUCCESS:
      return { ...state, help: { ...state.help, success: true } }

    case ERROR:
      return { ...state, help: { ...state.help, error: true, message: payload.message } }

    case CLOSE_SUCCESS:
      return { ...state, help: { ...state.help, success: false, getNext: true } }

    case CLOSE_ERROR:
      return { ...state, help: { ...state.help, error: false, message: '' } }

    // авторизация по логину/паролю
    case OPEN_ID:
      return { ...state, id: { ...state.id, open: true } }

    case CLOSE_ID:
      return { ...state, id: { ...state.id, open: false } }

    case SUCCESS_ID:
      return { ...state, id: { ...state.id } }

    // авторизация черз соц сети
    case OPEN_SOCIAL:
      return { ...state, social: { ...state.social, open: true } }

    case CLOSE_SOCIAL:
      return { ...state, social: { ...state.social, open: false } }

    case SOCIAL_AUTH_ERROR: // авторизация не завершена
      closeGlobalSocialWindow()
      console.log('ошибка, авторизация не завершена')
      return { ...state, error_message: { ...state.error_message, open: true, message: 'Ошибка авторизации' } }

    case SOCIAL_AUTH_2: // авторизация успешно завершена и закончена
      closeGlobalSocialWindow()
      console.log('авторизация успешно завершена и закончена')
      return {
        ...state,
        social: { ...state.social, open: false },
        social_success: { ...state.social_success, open: true }
      }

    case SOCIAL_AUTH_1: // авторизация успешно завершена но не закончена
      closeGlobalSocialWindow()
      console.log('авторизация успешно завершена но не закончена')
      return {
        ...state,
        social: { ...state.social, open: false },
        almost_complete: { ...state.almost_complete, open: true }
      }

    case CLOSE_ALMOST_COMPLETE:
      return { ...state, almost_complete: { ...state.almost_complete, open: false } }

    // получить happy id
    case OPEN_SIGN_UP:
      return { ...state, sign_up: { ...state.sign_up, open: true } }

    case CLOSE_SIGN_UP:
      return { ...state, sign_up: { ...state.sign_up, open: false } }

    case SUCCESS_SIGN_UP:
      return { ...state, sign_up: { ...state.sign_up, success: true } }

    case CLOSE_SIGN_UP_SUCCESS:
      return { ...state, sign_up: { ...state.sign_up, success: false } }

    // расскажите где вы работаете
    case OPEN_WHERE_DO_YOU_WORK:
      return { ...state, where_do_you_work: { ...state.where_do_you_work, open: true } }

    case CLOSE_WHERE_DO_YOU_WORK:
      return { ...state, where_do_you_work: { ...state.where_do_you_work, open: false } }

    case SUCCESS_WHERE_DO_YOU_WORK:
      return {
        ...state,
        where_do_you_work: { ...state.where_do_you_work, success: true },
        social_success: { ...state.social_success, open: true }
      }

    case CLOSE_SOCIAL_SUCCESS:
      return { ...state, social_success: { ...state.social_success, open: false } }

    case OPEN_SOCIAL_SUCCESS:
      return { ...state, social_success: { ...state.social_success, open: true } }

    // забыли пароль
    case OPEN_FORGOT_PASSWORD:
      return { ...state, forgot_password: { ...state.forgot_password, open: true } }

    case CLOSE_FORGOT_PASSWORD:
      return { ...state, forgot_password: defaultState.forgot_password }

    case ERROR_FORGOT_PASSWORD:
      return { ...state, forgot_password: { ...state.forgot_password, error: payload.message } }

    case SUCCESS_FORGOT_PASSWORD:
      return { ...state, forgot_password: { ...state.forgot_password, error: '', success: payload.success } }

    case SUCCESS_CHANGE_PASSWORD:
      return { ...state, forgot_password: { ...state.forgot_password, error: '', success: false, success2: true } }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const openSelector = (state) => state[moduleName].help.open
export const successSelector = (state) => state[moduleName].help.success
export const errorSelector = (state) => state[moduleName].help.error
export const messageSelector = (state) => state[moduleName].help.message
export const getNextSelector = (state) => state[moduleName].help.getNext

export const openSocialSelector = (state) => state[moduleName].social.open

/**
 * Action Creators
 * */

export const sendForm = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'request-json.php', params)
  if (data.success) dispatch({ type: SUCCESS })
  if (!data.success) dispatch({ type: ERROR, payload: { message: data.message } })
}

export const setSuccess = (params) => async (dispatch) => dispatch({ type: SUCCESS })

export const signInByID = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'login' } })
  if (data.errorCode) return dispatch({ type: ERROR_MESSAGE, payload: { message: data.errorMessage } })

  if (data.userId) dispatch({ type: OPEN_SOCIAL_SUCCESS })
}

export const sendPhoto = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-save-photo.php', params)
  console.log(data)
  // if (data.success) dispatch({ type: SUCCESS })
  // if (!data.success) dispatch({ type: ERROR, payload: { message: data.message } })
}

export const sendSignUp = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-registration.php', params)
  if (data.success) dispatch({ type: SUCCESS_SIGN_UP })
  if (!data.success) dispatch({ type: ERROR_MESSAGE, payload: { message: data.error } })
}

export const sendWhereDoYouWork = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-save-json.php', params)
  if (data.success) {
    await dispatch(getProfile())
    dispatch({ type: SUCCESS_WHERE_DO_YOU_WORK })
  }
  if (!data.success) dispatch({ type: ERROR_MESSAGE, payload: { message: data.message } })
}

export const saveNotEmployee = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-save-json.php', params)
  if (!data.success) {
    await dispatch(logOut())
    return dispatch({ type: ERROR_MESSAGE, payload: { message: data.error } })
  }

  dispatch({ type: OPEN_SOCIAL_SUCCESS })
}

export const sendForgotPassword = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'passwordrecovery' } })
  console.log(data)
  if (data.errorCode) return dispatch({ type: ERROR_FORGOT_PASSWORD, payload: { message: data.errorMessage } })
  dispatch({ type: SUCCESS_FORGOT_PASSWORD, payload: { success: true } })
}

export const sendChangePassword = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'passwordchange' } })
  console.log(data)
  if (data.errorCode) return dispatch({ type: ERROR_FORGOT_PASSWORD, payload: { message: data.errorMessage } })
  if (data.success) dispatch({ type: SUCCESS_CHANGE_PASSWORD })
}

const closeGlobalSocialWindow = () => {
  if (window.GLOBAL_SOCIAL_WINDOW && typeof window.GLOBAL_SOCIAL_WINDOW.close === 'function') {
    window.GLOBAL_SOCIAL_WINDOW.close()
  }
}

// позвать на помощь
export const openModal = () => ({ type: OPEN })
export const closeModal = () => ({ type: CLOSE })
export const closeError = () => ({ type: CLOSE_ERROR })
export const closeSuccess = () => ({ type: CLOSE_SUCCESS })

//
export const openSocialModal = () => ({ type: OPEN_SOCIAL })
export const closeSocialModal = () => ({ type: CLOSE_SOCIAL })

export const openIdModal = () => ({ type: OPEN_ID })
export const closeIdModal = () => ({ type: CLOSE_ID })
export const closeAlmostComplite = () => ({ type: CLOSE_ALMOST_COMPLETE })

export const openSignUp = () => ({ type: OPEN_SIGN_UP })
export const closeSignUp = () => ({ type: CLOSE_SIGN_UP })
export const closeSignUpSuccess = () => async (dispatch) => {
  await dispatch({ type: USER_AUTHORIZED })
  await dispatch(getProfile())
  dispatch({ type: CLOSE_SIGN_UP_SUCCESS })
}

export const openWhereDoYouWork = () => ({ type: OPEN_WHERE_DO_YOU_WORK })
export const closeWhereDoYouWork = () => ({ type: CLOSE_WHERE_DO_YOU_WORK })

export const closeErrorMessage = () => ({ type: CLOSE_ERROR_MESSAGE })
export const closeSocialSuccess = () => async (dispatch) => {
  await dispatch({ type: USER_AUTHORIZED })
  await dispatch(getProfile())
  dispatch({ type: CLOSE_SOCIAL_SUCCESS })
}

export const openForgotPassword = () => ({ type: OPEN_FORGOT_PASSWORD })
export const closeForgotPassword = () => ({ type: CLOSE_FORGOT_PASSWORD })
export const notSuceessForgotPassword = () => ({ type: SUCCESS_FORGOT_PASSWORD, payload: { success: false } })
